import Grid, { Label, OverrideButton, Value } from './PricingGrid';
import { useQuoteFinalPremium } from '../../hooks/quotes';

const CatastrophePanel = ({ showModal }: { showModal: () => void }) => {
    const { finalPremium } = useQuoteFinalPremium();

    if (!finalPremium) {
        return null;
    }
    const {
        air_broker_cat_loss_cost_final,
        air_cat_loss_cost_final,
        air_modeled_cat_premium_final,
        air_total_cat_premium_final,
        inland_flood_premium_final,
        rms_broker_cat_loss_cost_final,
        rms_cat_loss_cost_final,
        rms_modeled_cat_premium_final,
        rms_total_cat_premium_final,
    } = finalPremium;

    const air_pricing_multiple = computePricingMultiple(air_modeled_cat_premium_final, air_cat_loss_cost_final);
    const rms_pricing_multiple = computePricingMultiple(rms_modeled_cat_premium_final, rms_cat_loss_cost_final);

    return (
        <>
            <div className="info-block catastrophe-premium w-260">
                <div className="header">Catastrophe Premium</div>

                <Grid columns={3} className="gap-x-1 gap-y-0_5 padding-light">
                    <label className="sub-header"></label>
                    <div className="sub-header border-bottom">AIR</div>
                    <div className="sub-header border-bottom">RMS</div>

                    <Label>Cat Loss</Label>
                    <OverrideButton onClick={() => showModal()}>
                        <Value format={{ type: 'number', delimiter: ',' }} value={air_broker_cat_loss_cost_final} />
                    </OverrideButton>
                    <OverrideButton onClick={() => showModal()}>
                        <Value format={{ type: 'number', delimiter: ',' }} value={rms_broker_cat_loss_cost_final} />
                    </OverrideButton>

                    <Label>Risk-Adjusted Cat Loss</Label>
                    <OverrideButton onClick={() => showModal()}>
                        <Value format={{ type: 'number', delimiter: ',' }} value={air_cat_loss_cost_final} />
                    </OverrideButton>
                    <OverrideButton onClick={() => showModal()}>
                        <Value format={{ type: 'number', delimiter: ',' }} value={rms_cat_loss_cost_final} />
                    </OverrideButton>

                    <Label>Pricing Multiple</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={air_pricing_multiple} />
                    <Value format={{ type: 'number', decimals: 2 }} value={rms_pricing_multiple} />

                    <Label>Modeled Cat Premium</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={air_modeled_cat_premium_final} />
                    <Value format={{ type: 'number', delimiter: ',' }} value={rms_modeled_cat_premium_final} />

                    <div className="grid-3-wide grid-spacer"></div>

                    <Label>Inland Flood Premium</Label>
                    <Value format={{ type: 'number', delimiter: ',' }} value={inland_flood_premium_final} />
                    <Value format={{ type: 'number', delimiter: ',' }} value={inland_flood_premium_final} />

                    <div className="grid-3-wide grid-spacer"></div>

                    <Label>
                        <b>Total Cat Premium</b>
                    </Label>
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={air_total_cat_premium_final} />
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={rms_total_cat_premium_final} />
                </Grid>
            </div>
        </>
    );
};

export default CatastrophePanel;

const computePricingMultiple = (modeledCatPremium: string, catLossCost: string) => {
    if (!modeledCatPremium || !catLossCost) return undefined;
    return +modeledCatPremium / +catLossCost;
};
