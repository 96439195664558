import { useQuoteFinalPremium } from '../../hooks/quotes';
import Grid, { Label, Value } from './PricingGrid';
import { getAmountSubjectRate, getTivRate } from './utils';

const TechnicalPremiumPanel = () => {
    const { finalPremium } = useQuoteFinalPremium();
    if (!finalPremium) {
        return null;
    }
    const { air_technical_premium_before_tf, amount_subject, is_valid, rms_technical_premium_before_tf, tiv } = finalPremium;
    const airTivRate = is_valid ? getTivRate(air_technical_premium_before_tf, tiv) : null;
    const rmsTivRate = is_valid ? getTivRate(rms_technical_premium_before_tf, tiv) : null;
    const airAmountSubjectRate = is_valid ? getAmountSubjectRate(air_technical_premium_before_tf, amount_subject) : null;
    const rmsAmountSubjectRate = is_valid ? getAmountSubjectRate(rms_technical_premium_before_tf, amount_subject) : null;

    return (
        <>
            <div className="info-block technical-premium w-260">
                <div className="header">Technical Premium</div>
                <Grid columns={3} className="gap-x-1 gap-y-0_5 padding-light">
                    <label className="sub-header"></label>
                    <div className="sub-header border-bottom">Air</div>
                    <div className="sub-header border-bottom margin-left-light">RMS</div>

                    <Label>
                        <b>Premium Before Fees</b>
                    </Label>
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={air_technical_premium_before_tf} />
                    <Value bold format={{ type: 'number', delimiter: ',' }} value={rms_technical_premium_before_tf} />

                    <div className="grid-3-wide grid-spacer"></div>

                    <Label>Rate (TIV)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={airTivRate} />
                    <Value format={{ type: 'number', decimals: 2 }} value={rmsTivRate} />

                    <Label>Rate (Amount Subject)</Label>
                    <Value format={{ type: 'number', decimals: 2 }} value={airAmountSubjectRate} />
                    <Value format={{ type: 'number', decimals: 2 }} value={rmsAmountSubjectRate} />
                </Grid>
            </div>
        </>
    );
};

export default TechnicalPremiumPanel;
