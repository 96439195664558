import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { getFormattedTask } from '@archinsurance-viki/property-jslib/src/components/tasks/VikiTasksPanel';
import VikiSelfDestructibleTask from '@archinsurance-viki/property-jslib/src/components/tasks/VikiTasks';
import { TasksPanelV2 } from '@archinsurance-viki/property-jslib/src/components/tasks/TasksPanelV2';

import { useAppSelector } from '../hooks/redux';
import { dissmissSubscribedTask, selectTaskById, selectVisibleTasksByAccount, selectVisibleTasksByType, updateTask } from '../reducers/tasks';
import { useCancelTaskMutation, useTasksSubscriptionQuery } from '../services/endpoints/tasks';
import { BackgroundTask, BackgroundTaskStatus } from '../ts-types/DataTypes';
import { getTaskSubtitles } from '../utils/task-helpers';
import { usePusherEvent } from '@archinsurance-viki/property-jslib/src/hooks/pusher';
import { useAppDispatch } from '../hooks/redux';
import { formatBasicDate } from '@archinsurance-viki/property-jslib/src/utils/date-helpers';
import { useAppContext } from '../hooks/context';

const INCOMPLETE_STATUSES: BackgroundTaskStatus[] = ['IN_PROGRESS', 'QUEUED'];
export const DEFAULT_TASK_FILTER = { status: INCOMPLETE_STATUSES, ordering: '-modified', modified__gte: formatBasicDate(new Date()) };

const TaskWrapper = ({ task, hideDismiss }: { task: BackgroundTask; hideDismiss?: boolean }) => {
    const { ENV } = useAppSelector(state => state.global);
    const dispatch = useAppDispatch();
    const [triggerCancel] = useCancelTaskMutation();

    const onCancel = () => triggerCancel({ id: task.id });
    const { onDismissTask, ...formattedTask } = getFormattedTask(
        { taskId: `${task.id}`, taskType: task.task_type, title: task.task_title, ...task },
        getTaskSubtitles,
        ENV,
        () => dispatch(dissmissSubscribedTask(task.id))
    );
    return (
        <VikiSelfDestructibleTask
            key={task.id}
            allowSelfDestruct={false}
            onCancel={task.is_cancellable && INCOMPLETE_STATUSES.includes(task.status) ? onCancel : undefined}
            onDismissTask={hideDismiss ? null : onDismissTask}
            {...formattedTask}
        />
    );
};

export const AccountTasksPanel = () => {
    const { accountId: paramAccountId, submissionId: paramSubmissionId } = useParams<{ accountId: string; submissionId: string }>();
    const { currentAccountId } = useAppContext();

    const id = paramAccountId ?? currentAccountId ?? paramSubmissionId;
    const filterParams = useMemo(() => ({ ...DEFAULT_TASK_FILTER, account_id: +id }), [id]);

    const pusherChannelName = `account-tasks-${id}`;
    useTasksSubscriptionQuery({ pusherChannelName, filterParams }, { skip: !id, refetchOnMountOrArgChange: true });
    const visibleAccountTasks = useAppSelector(state => selectVisibleTasksByAccount(state, +id));

    // handle case where grid doesn't have selected account id yet
    if (!id) {
        return null;
    }

    return <SubscribedTasksPanel pusherChannelName={pusherChannelName} tasks={visibleAccountTasks} />;
};

export const TaskTypePanel = ({ taskType }: { taskType: string }) => {
    const pusherChannelName = `tasks-${taskType}`;
    const filterParams = useMemo(() => ({ ...DEFAULT_TASK_FILTER, task_type: taskType }), [taskType]);
    useTasksSubscriptionQuery({ pusherChannelName, filterParams }, { refetchOnMountOrArgChange: true });
    const visibleTasks = useAppSelector(state => selectVisibleTasksByType(state, taskType));
    return <SubscribedTasksPanel pusherChannelName={pusherChannelName} tasks={visibleTasks} />;
};

// Display selected task without dismiss option on Tasks page
export const SelectedTaskPanel = ({ taskId }: { taskId: number }) => {
    const pusherChannelName = `task-${taskId}`;
    useTasksSubscriptionQuery({ pusherChannelName, filterParams: { id: taskId } });
    const task = useAppSelector(state => selectTaskById(state, taskId));

    if (!task) {
        return null;
    }
    return <SubscribedTasksPanel pusherChannelName={pusherChannelName} tasks={[task]} hideDismiss />;
};

// Component to manage task pusher updates and update redux accordingly
export const SubscribedTasksPanel = ({
    pusherChannelName,
    tasks,
    hideDismiss = false,
}: {
    pusherChannelName: string;
    tasks: BackgroundTask[];
    hideDismiss?: boolean;
}) => {
    const dispatch = useAppDispatch();

    const onEvent = (updatedTask: BackgroundTask) => {
        dispatch(updateTask(updatedTask));
    };

    usePusherEvent(pusherChannelName, 'updated', onEvent);
    usePusherEvent(pusherChannelName, 'done', onEvent);

    return (
        <TasksPanelV2>
            {tasks.map((task: BackgroundTask) => (
                <TaskWrapper key={`${pusherChannelName}-${task.id}`} task={task} hideDismiss={hideDismiss} />
            ))}
        </TasksPanelV2>
    );
};
