import _ from 'lodash';

import { NoteDataType } from '@archinsurance-viki/property-jslib/src/ts-types/NoteTypes';
import { formatCurrency } from '@archinsurance-viki/property-jslib/src/utils/converters';
import {
    ALL_NOTE_TYPES,
    NOTE_TYPE_ADDITIONAL_DETAILS,
    NOTE_TYPE_GENERAL_NOTE,
    NOTE_TYPE_OCCUPANCY_NOTE,
    NOTE_TYPE_UNDERWRITER_RATIONALE,
} from '../constants/NoteConstants';
import { TRANSACTION_TYPES } from '../constants/TransactionConstants';
import { TRANSACTION_STATUS } from '@archinsurance-viki/property-jslib/src/constants/Constants';

import { SubmissionDataType, TransactionDataType } from '../ts-types/DataTypes';
import { getSharedAndLayeredDescription, trimAndParse } from '../features/coverage-terms/utils/coverage-utils';

export const getPctOfTechnicals = (value: any, { currentSubmission }: Record<string, any>) => {
    if (!currentSubmission) return;

    let charged_premium_before_ttf = currentSubmission.charged_premium_before_ttf;

    let charged_terror_premium = currentSubmission.charged_terror_premium;
    let air_technical_premium_before_ttf = currentSubmission.air_technical_premium_before_tf - charged_terror_premium;
    let rms_technical_premium_before_ttf = currentSubmission.rms_technical_premium_before_tf - charged_terror_premium;

    let fmt = v => {
        return v ? `${v.toFixed(0)}%` : '-';
    };
    let air = (100 * charged_premium_before_ttf) / air_technical_premium_before_ttf;
    let rms = (100 * charged_premium_before_ttf) / rms_technical_premium_before_ttf;

    return `${fmt(air)} / ${fmt(rms)}`;
};

export const calculateRates = (currentQuote: Record<string, any>, field: string) => {
    if (!currentQuote || !currentQuote.id || !currentQuote.final_premium) return '-';
    return ((100 * currentQuote.final_premium[field]) / (currentQuote.final_premium.total_building_tiv ?? 0)).toFixed(2);
};

export const groupNotesByNoteType = (notes: NoteDataType[]) => {
    if (notes === null || notes === undefined) {
        return {};
    }
    let keep_names = [NOTE_TYPE_OCCUPANCY_NOTE, NOTE_TYPE_UNDERWRITER_RATIONALE, NOTE_TYPE_ADDITIONAL_DETAILS];
    const notesByType = _.groupBy(notes, c => (_.includes(keep_names, c['note_type']) ? c['note_type'] : NOTE_TYPE_GENERAL_NOTE));

    for (let type of ALL_NOTE_TYPES) {
        if (!notesByType[type]) {
            notesByType[type] = [] as any; // after loading, ensure these are all present.
        }
    }

    return notesByType;
};

export const makeRawSideBySide = (val1: number, val2: number) => {
    let n1 = formatCurrency(val1);
    let n2 = formatCurrency(val2);

    return `${n1} / ${n2}`;
};

export const makeSideBySide = (currentQuote: Record<string, any>, f1: string, f2: string) => {
    if (!currentQuote || !currentQuote.final_premium) {
        return undefined;
    }

    return makeRawSideBySide(currentQuote.final_premium[f1], currentQuote.final_premium[f2]);
};

export const isLayeredRatio = (policy_coverage: Record<string, any>, tiv: number) => {
    const {
        occurrence_limit,
        occurrence_participation,
        occurrence_attachment,
        occurrence_participation_fmt,
        occurrence_participation_dollar_amt,
        policy_is_layered,
    } = policy_coverage;

    return getSharedAndLayeredDescription(
        {
            policy_is_layered,
            occurrence_participation_fmt,
            occurrence_participation: +occurrence_participation,
            occurrence_participation_dollar_amt: trimAndParse(occurrence_participation_dollar_amt),
            occurrence_attachment: trimAndParse(occurrence_attachment),
            occurrence_limit: trimAndParse(occurrence_limit),
        },
        tiv
    );
};

export const maxBuildingTIV = (currentQuote: Record<string, any>) => {
    if (!currentQuote) return undefined;
    return parseFloat(currentQuote.max_building_tiv);
};

export const maxFloodLimit = (pq: Record<string, any>) => {
    if (!pq) return 0;
    let { flood_1_limit, flood_2_limit, flood_3_limit, flood_4_limit } = pq.policy_coverage;
    return Math.max(flood_1_limit, flood_2_limit, flood_3_limit, flood_4_limit);
};

export const getPolicyCoverageItem = (pq: Record<string, any>, f1: string) => {
    if (!pq) return 0;
    return pq.policy_coverage[f1];
};

export const calculateCatPool = (currentSubmission: SubmissionDataType) => {
    if (!currentSubmission || !currentSubmission.carrierData) return undefined;
    let pools = _.filter(
        _.uniq(
            _.map(currentSubmission.carrierData, cd => {
                return cd.pool_name;
            })
        ),
        c => c === 'AIR' || c === 'RMS'
    );

    if (pools.length === 0) {
        return 'No Carriers';
    }

    if (pools.length > 1) {
        return 'Both';
    }

    return pools[0];
};

export const isBoundReinstatementOrReversion = transaction =>
    transaction &&
    transaction.status === TRANSACTION_STATUS.BOUND &&
    (transaction.transaction_type === TRANSACTION_TYPES.REINSTATEMENT || transaction.transaction_type === TRANSACTION_TYPES.REVERSION);

export const isInReviewReinstatementOrReversion = (transaction: TransactionDataType) =>
    transaction &&
    transaction.status === TRANSACTION_STATUS.IN_REVIEW &&
    (transaction.transaction_type === TRANSACTION_TYPES.REINSTATEMENT || transaction.transaction_type === TRANSACTION_TYPES.REVERSION);
