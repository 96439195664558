import * as React from 'react';
import * as _ from 'lodash';
import { CarrierPool } from '../../ts-types/ApiTypes';
import { formatNumber } from '@archinsurance-viki/property-jslib/src/utils/converters';

import CarrierDetailRow from './rows/CarrierDetailRow';

type propTypes = {
    carrierPools: Record<string, any>[];
    parentProps: Record<string, any>;
};
const CARRIER_HEADING = 'Carrier Participation';
const NO_CARRIER_MSG = 'No eligible carrier pools.';

export default class CarrierParticipationTable extends React.Component<propTypes> {
    renderCarrierParticipations = () => {
        let { carrierPools } = this.props;
        let { parentProps } = this.props;
        const { currentQuote } = parentProps;
        const hasMultipleActivePools = carrierPools.filter((pool: CarrierPool) => pool.is_active_for_quote).length > 1;

        if (carrierPools.length === 0) {
            return <div key="carriers">{NO_CARRIER_MSG}</div>;
        } else {
            return (
                <div key="carriers">
                    {carrierPools.map(pool =>
                        pool.is_active_for_quote ? (
                            <div>
                                {hasMultipleActivePools && currentQuote && (
                                    <>
                                        <div className="info-rows">
                                            <label
                                                style={{ color: '#9c9c9c', fontSize: '0.75rem', fontWeight: 700, letterSpacing: '2px', marginBottom: '6px' }}
                                            >
                                                {pool.pool_name === 'LAYER_A' ? 'LAYER A PREMIUM' : 'LAYER B PREMIUM'}:{' '}
                                                {formatNumber(
                                                    pool.pool_name === 'LAYER_A' ? currentQuote?.layer_1_premium : currentQuote?.layer_2_premium,
                                                    '$',
                                                    undefined,
                                                    0
                                                )}
                                            </label>
                                        </div>
                                    </>
                                )}
                                <table key={pool.pool_name} className="spaced">
                                    <thead>
                                        <tr className="headers">
                                            <th className="frm-lb size-6">{pool.pool_name}</th>
                                            <th className="size-5">Default</th>
                                            <th className="size-5">Override</th>
                                            <th className="size-5">Final</th>
                                            <th className="size-5">Premium</th>
                                            <th className="size-5">Commission</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pool.carriers.map(carrier => (
                                            <CarrierDetailRow
                                                disabled={true}
                                                key={carrier.carrier_short_name}
                                                carrier={{ name: carrier.carrier_short_name }}
                                                object={carrier}
                                                parentProps={{ ...parentProps }}
                                            />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : null
                    )}
                </div>
            );
        }
    };

    render(): React.ReactNode {
        return (
            <div className="info-block carrier-participation flex-table">
                <div className="header">
                    <div className="header-text">{CARRIER_HEADING}</div>
                </div>
                <div>{this.renderCarrierParticipations()}</div>
            </div>
        );
    }
}
