import { useEffect, useState } from 'react';
import classNames from 'classnames';

import { PANELS } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { AopPremiumPanel, CatastrophePanel, TechnicalPremiumPanel, TabsPanel } from '../../components/pricing-page';
import { PricingTab } from '../../components/pricing-page';
import { Spinner } from '@archinsurance-viki/property-jslib/src/components/widgets/Spinner';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useAppContext, useAppContextFns } from '../../hooks/context';
import { useQuoteId, useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import { useRepriceMutation } from '../../services/apiSlice';
import { CenteredModal } from '@archinsurance-viki/property-jslib/src/components/modals';
import { VIRA_MODAL_TYPES } from '../../constants/Modal';
import { Redirect, useParams } from 'react-router-dom';
import { PricingPageWrapper } from './PricingPageWrapper';
import { PricingPageHeader } from '../../components/pricing-page';

export function PricingPage() {
    const { accountId, submissionId } = useParams<{ accountId: string; submissionId: string }>();
    const [showModal, setShowModal] = useState(false);
    const dispatch = useAppDispatch();

    const quoteId = useQuoteId();
    const appContext = useAppContext();
    const appContextFns = useAppContextFns();
    const [currentTab, setTab] = useState(PricingTab.METRICS);
    const uiState = useAppSelector(state => state.uiState);
    const { currentSubmission } = appContext;

    const { finalPremium, isError } = useQuoteFinalPremium();
    const { policyCoverage } = useQuotePolicyCoverage();
    const [triggerReprice] = useRepriceMutation({ fixedCacheKey: `${quoteId}` });

    const isWaiting = !finalPremium || !policyCoverage;
    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const showPricingTabTodo = featureFlags?.pricingV2TabTodo || false;
    const showComparablesTab = featureFlags?.showComparablesTab || false;
    const showLayeringAALsPMLs = featureFlags?.show_layering_aals_pmls || false;
    const showTechnicalPremiumByPeril = featureFlags?.show_technical_premium_by_peril || false;

    useEffect(() => {
        if (quoteId) {
            triggerReprice({ id: quoteId });
        }
    }, [dispatch, quoteId, triggerReprice]);

    const slidePanelOpen = uiState.openPanels[PANELS.RIGHT];
    const panelActionsProps = {
        appContext,
        appContextFns,
    };

    if (isError && !finalPremium) {
        // invalid url
        return <Redirect to={`/submissions/${accountId}/${submissionId}/underwriting/`} />;
    }

    if (isWaiting) {
        return (
            <PricingPageWrapper
                panelActionsProps={panelActionsProps}
                slidePanelOpen={slidePanelOpen}
                uiState={uiState}
                header={<PricingPageHeader />}
                hideHeader
            >
                <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-p-0 h-100">
                    <Spinner size="lg" />
                </div>
            </PricingPageWrapper>
        );
    }

    return (
        <PricingPageWrapper panelActionsProps={panelActionsProps} slidePanelOpen={slidePanelOpen} uiState={uiState} header={<PricingPageHeader />}>
            <div className={classNames('grid-layout pricing padding-standard', { opened: slidePanelOpen })}>
                <AopPremiumPanel showModal={() => setShowModal(true)} />
                <CatastrophePanel showModal={() => setShowModal(true)} />
                <div className={classNames('pricing-tab-panel info-block overflow-hidden has-normal-btn', { opened: slidePanelOpen })}>
                    <TabsPanel
                        currentSubmission={currentSubmission}
                        currentTab={currentTab}
                        onChangeTab={newTab => setTab(newTab)}
                        showPricingTabTodo={showPricingTabTodo}
                        showComparablesTab={showComparablesTab}
                        showLayeringAALsPMLs={showLayeringAALsPMLs}
                        showTechnicalPremiumByPeril={showTechnicalPremiumByPeril}
                    />
                </div>
                <TechnicalPremiumPanel />
            </div>
            {showModal && (
                <CenteredModal
                    modalData={{ disableModalResizable: true, noDismiss: true }}
                    modalType={VIRA_MODAL_TYPES.OVERRIDE_MODAL}
                    onClose={() => setShowModal(false)}
                />
            )}
        </PricingPageWrapper>
    );
}
