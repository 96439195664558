import { DeductibleFormatsType } from '@archinsurance-viki/property-jslib/src/constants/Constants';
import { BACKGROUND_TASK_STATUSES } from '@archinsurance-viki/property-jslib/src/constants/TaskConstants';
import { PEE_FIELDS } from '../constants/PolicyCoverageConstants';
import { BackgroundTask, BackgroundTaskStatus, BuildingDataType, QuoteType, ReferralDataType, SubmissionDataType } from './DataTypes';
import { Choice, CurrencyType } from '@archinsurance-viki/property-jslib/src/ts-types/DataTypes';
import { SUBMISSION_STATUS } from '../constants/Submission';
import { GlossaryItemType } from '@archinsurance-viki/property-jslib/src/ts-types/TableTypes';

export type BackgroundTasksResult = {
    count: number;
    results: BackgroundTask[];
};

export type DjangoFilterFields = {
    ordering: string; // TODO: narrow this type more
};

export type BackgroundTaskFilterFields = {
    status?: BackgroundTaskStatus[];
} & Partial<DjangoFilterFields> &
    Partial<Omit<BackgroundTask, BackgroundTaskFilterSkipFields[number]>>;

type BackgroundTaskFilterSkipFields = [
    'data_sent',
    'data_received',
    'status_display',
    'task_type_display',
    'queued',
    'fail_message',
    'failed',
    'in_progress',
    'succeeded',
    'total_duration',
    'last_updated',
    'errors',
    'sort_by_time',
    'status',
    'submission__site_id',
    'new_submission__site_id'
];

export interface RunCatModelsQueryOptions {
    transactionId: number;
}

export interface SubmitCatModelsQueryOptions {
    submissionId: number;
    quoteIds: number[] | string[];
}

export type ArchPerilResult<Peril> = {
    name: Peril;
    abbreviation: string;
};

export type ArchRegionResult<Peril> = {
    arch_peril: ArchPerilResult<Peril>;
    name: string;
    county_fips_code: string;
    state_abbreviation: string;
};

export type LossByRegionAndPerilResult<Peril extends string> = {
    arch_region: ArchRegionResult<Peril>;
    created: string;
    gross_aal: number;
    ground_up_aal: number;
    layer_name: Peril;
    modified: string;
    return_period_100_gross_pml: number;
    return_period_100_ground_up_pml: number;
    return_period_200_gross_pml: number;
    return_period_200_ground_up_pml: number;
    return_period_250_gross_pml: number;
    return_period_250_ground_up_pml: number;
    return_period_500_gross_pml: number;
    return_period_500_ground_up_pml: number;
    return_period_1000_gross_pml: number;
    return_period_1000_ground_up_pml: number;
};

type LossSums<Peril extends string> = Pick<
    LossByRegionAndPerilResult<Peril>,
    'gross_aal' | 'return_period_250_ground_up_pml' | 'return_period_1000_ground_up_pml'
>;

export type CatAnalysisResults = {
    loss_analysis_result_id: number;
    air_gross_aal: number;
    standard_deviation: number;
    loss_by_region_and_peril: {
        earthquake?: LossByRegionAndPerilResult<'Earthquake'>[];
        wind?: LossByRegionAndPerilResult<'Wind'>[];
        flood?: LossByRegionAndPerilResult<'Flood'>[];
    };
    loss_by_peril: {
        earthquake?: LossSums<'Earthquake'>;
        wind?: LossSums<'Wind'>;
        flood?: LossSums<'Flood'>;
    };
};

export type OsmPmlEventResults = {
    group_mfl_wout_surge: string;
    group_pml_wout_surge: string;
    group_mfl_w_surge: string;
    group_pml_w_surge: string;
    pml_age_factor: number;
    pml_cns_factor: number;
    pml_county_factor: number;
    pml_dtc_factor: number;
    pml_state_factor: number;
};

export interface CatModelsResult {
    success: boolean;
    quote_ids: number[] | string[];
}

export interface PEEQueryOptions {
    id?: string | number;
}

export interface PEECoverageType {
    prefix: string;
    description: string;
    col: string;
    base_row: boolean;
    field: string | null;
    maximum: number;
    std_limit: number;
    included: number;
    override: number | null;
    limit_final: number;
}
export interface PEEQueryResult {
    policy_coverage_id: number;
    bpp_coverages: PEECoverageType[];
    time_elements: PEECoverageType[];
    quote_id: number;
}
export interface MetricsQueryOptions {
    year: string | number;
}

export interface CarriersQueryOptions {
    quoteId: number | string;
}

export interface BuildingPremiumQueryOptions {
    quoteId: number | string;
}

export interface MarginalPMLQueryOptions {
    quoteId: number | string;
}

export interface Carrier {
    actual_allocated_pct: number;
    actual_allocated_premium: string;
    actual_allocated_premium_delta: number;
    carrier_id: number;
    carrier_name: string;
    carrier_short_name: string;
    commission_amount: string;
    commission_amount_delta: number;
    default_participation_pct: string;
    final_net_participation_pct: string;
    final_participation_pct: string;
    id: number;
    override_participation_pct: string | null;
    pool_id: number;
    quote_id: number;
    submission_id: number;
}

export interface FinalCarrierData {
    carrier_id: number;
    carrier_name: string;
    carrier_short_name: string;
    pool_id: number;
    pool_name: string;
    final_participation_pct: string | null;
    final_net_participation_pct: string | null;
    default_participation_pct: string | null;
    override_participation_pct: string | null;
    commission_amount: string | null;
    actual_allocated_premium: string | null;
    actual_allocated_pct: number | null;
}

export interface CarrierPool<P extends string = string> {
    actual_pool_allocated_pct: string;
    is_active_for_quote: boolean;
    pool_id: number;
    pool_name: P;
    display_name: string;
    quote_id: number;
    submission_id: number;
    carriers: Carrier[];
}

export interface CarriersResult<P extends string> {
    carrier_pools: CarrierPool<P>[];
    quote_id: number;
}

export type AccountTransactionCarrierResult = Pick<FinalCarrierData, 'final_net_participation_pct' | 'actual_allocated_premium' | 'carrier_name' | 'pool_name'>;

/**
 * TODO: This type was written by looking at a JSON final premium in a network response.
 * However, there are some fields that are used that did not exist in that response.
 * This means one of two things:
 * 1. Those fields indeed do not exist, and the frontend is wrong to use them
 * 2. There are technically multiple final premium types for different endpoints
 *
 * I'm inclined to lean toward #2, which is unfortunate because it means we'll need
 * multiple types. To resolve this, we should do one of the following:
 * 1. Align the endpoints so that they all use the same type (serializer)
 * 2. Formalize some way to have multiple types based off of one "master" type.
 *
 * If we're going to start using dynamic rest more, then solution #2 is probably what
 * we want to do. Then we can define strongly-typed queries that provide the correct
 * types based off of the requested fields.
 *
 * NOTE: this problem is definitely not exclusive to final premium.
 * It's just particularly apparent here because the type is ENORMOUS.
 * This problem likely applies to nearly all of our types due to the overly
 * flexible nature of django.
 * </rant>
 */
export interface FinalPremiumType {
    aop: boolean;
    agent_commission_rate: string | null;
    air_aep_200: string;
    air_aep_250: string;
    air_aep_1000: string;
    air_aop_expense_load: string;
    air_aop_expense_load_final: string;
    air_broker_cat_aal: string;
    air_broker_cat_aal_final: string;
    air_broker_cat_aal_override: string;
    air_broker_cat_loss_cost: string;
    air_broker_cat_loss_cost_final: string;
    air_broker_cat_loss_cost_override: string;
    air_cat_aal: string;
    air_cat_aal_final: string;
    air_cat_aal_override: string;
    air_cat_expense_load: string;
    air_cat_expense_load_final: string;
    air_cat_expense_load_override: string;
    air_cat_expense_load_without_flood: string;
    air_cat_expense_load_without_flood_final: string;
    air_cat_loss_cost: string;
    air_cat_loss_cost_final: string;
    air_cat_loss_cost_override: string;
    air_cat_multiple_with_loss_adjustment: string;
    air_cat_net_premium: string;
    air_cat_net_premium_final: string;
    air_cat_net_premium_original: string;
    air_cat_net_premium_override: string;
    air_cat_net_premium_without_flood: string;
    air_cat_net_premium_without_flood_final: string;
    air_cat_net_premium_without_flood_override: string;
    air_layerless_cat_loss_cost_final: string | null;
    air_layerless_oep_200: string;
    air_layerless_oep_250: string;
    air_layerless_oep_1000: string;
    air_modeled_cat_premium: string;
    air_modeled_cat_premium_final: string | null;
    air_oep_200: string;
    air_oep_250: string;
    air_oep_1000: string;
    air_pct_of_technical: number;
    air_pee_adjusted_limits_final: string;
    air_pee_included_limits_final: string;
    air_pee_interim_data: unknown;
    air_pee_limits_final: string;
    air_property_enhancement_loss_cost: string;
    air_property_enhancement_loss_cost_adjusted_limits: string;
    air_property_enhancement_loss_cost_final: string;
    air_property_enhancement_loss_cost_override: string;
    air_technical_aop_premium: number | null;
    air_technical_premium_before_tf: number | null;
    air_total_aop_loss_cost_adj_for_participation: string;
    air_total_aop_loss_cost_adj_for_participation_final: string;
    air_total_aop_loss_cost_full_participation: string;
    air_total_aop_loss_cost_full_participation_final: string;
    air_total_aop_premium: string;
    air_total_aop_premium_final: string;
    air_total_cat_premium: string;
    air_total_cat_premium_final: string | null;
    air_total_earthquake_cat_premium_final: string | null;
    air_total_wind_cat_premium_final: string | null;
    air_total_expected_loss: string;
    air_total_premium: string;
    air_total_premium_original: string;
    amount_subject: string;
    aop_expense_load: string;
    aop_expense_load_before_eb: string;
    aop_expense_load_before_eb_final: string;
    aop_expense_load_final: string;
    assessments: string;
    assessments_final: string;
    assessments_override: string;
    base_premium_final: number | null;
    business_strategy: string;
    business_strategy_final: string;
    business_strategy_override: string;
    charged_aop_premium: number;
    charged_inland_flood_premium: number;
    charged_modeled_cat_premium: number;
    charged_premium_before_tf: number;
    charged_premium_before_tf_net_of_agent_commission: number;
    data_completeness_index: string;
    equipment_breakdown_external_premium_override: string;
    equipment_breakdown_loss_cost: string;
    equipment_breakdown_loss_cost_final: string;
    equipment_breakdown_loss_cost_override: string;
    equipment_breakdown_premium: string;
    equipment_breakdown_premium_final: string;
    equipment_breakdown_premium_adj_for_participation_final: number | null;
    equipment_breakdown_premium_model: string;
    equipment_breakdown_premium_adj_for_participation_model: number | null;
    equipment_breakdown_referral_required_reasons: string[];
    error_message: string | null;
    final_premium_after_taxes: string;
    final_premium_after_taxes_net_of_agent_commission: number | null;
    gross_amount_subject_account_rate: number;
    gross_tiv_account_rate: number;
    ground_up_base_premium_final: number | null;
    home_state: string;
    id: number;
    initialized_date: string;
    inland_flood_expense_load: string;
    inland_flood_expense_load_final: string;
    inland_flood_loss_cost: string;
    inland_flood_loss_cost_final: string;
    inland_flood_loss_cost_override: string;
    inland_flood_premium: string;
    inland_flood_premium_final: string | null;
    inspection_fees: string;
    inspection_fees_final: string;
    inspection_fees_override: string;
    is_equipment_breakdown_included: boolean;
    is_equipment_breakdown_referral_required: boolean;
    is_flood_included: boolean;
    is_pee_standard_limits_included: boolean;
    is_terror_included: boolean;
    is_valid: boolean;
    itv_account_quality_index: string;
    last_successfully_priced_on: string | null;
    last_updated_on: string;
    layer_aop_loss_costs: string;
    layer_aop_loss_costs_final: string;
    layer_base_premium_final: number | null;
    layered_pricing_factor: string;
    layered_pricing_factor_final: string;
    layered_pricing_factor_override: string;
    limit_of_insurance_credit: string;
    limit_of_insurance_credit_final: string;
    limit_of_insurance_credit_override: string;
    loss_history_credit: string;
    loss_history_credit_final: string;
    loss_history_credit_override: string;
    max_building_tiv: string;
    max_location_tiv: string;
    modeling_fee: string;
    modeling_fee_final: string;
    modeling_fee_override: string;
    net_amount_subject_account_rate: number;
    net_tiv_account_rate: number;
    number_of_buildings: number;
    number_of_locations: number;
    occurrence_participation: number;
    other_debits_credits: string;
    other_debits_credits_final: string;
    other_debits_credits_override: string;
    pee_adjusted_limits_final: string;
    pee_included_limits_final: string;
    pee_interim_data: {
        aop_rate: string;
        cat_rate: string;
        coverages: {
            rate: string;
            field: string;
            addl_loss_cost: string;
            adjusted_limit: number;
            included_limit: number;
        }[];
        final_base_rate: string;
        ord_law_rate: string;
    };
    pee_limits_final: string;
    pee_overrides: unknown;
    policy_form: string;
    predominant_construction_type: string;
    predominant_county: string;
    predominant_occupancy: string;
    predominant_region: string;
    predominant_state: string;
    predominant_zipcode: string;
    prop_enhancement_endorsement: boolean;
    property_enhancement_loss_cost: string;
    property_enhancement_loss_cost_adjusted_limits: string;
    property_enhancement_loss_cost_final: string;
    property_enhancement_loss_cost_included_limits: string;
    property_enhancement_loss_cost_override: string;
    property_enhancement_premium_final: string;
    property_enhancement_premium_adj_for_participation_final: number | null;
    quote_id: number;
    risk_adjusted_rate_change: number;
    rms_aep_200: string | null;
    rms_aep_250: string | null;
    rms_aep_1000: string | null;
    rms_aop_expense_load: string;
    rms_aop_expense_load_final: string;
    rms_broker_cat_aal: string;
    rms_broker_cat_aal_final: string;
    rms_broker_cat_aal_override: string;
    rms_broker_cat_loss_cost_final: string;
    rms_cat_aal: string;
    rms_cat_aal_final: string;
    rms_cat_aal_override: string;
    rms_cat_expense_load: string;
    rms_cat_expense_load_final: string;
    rms_cat_expense_load_override: string;
    rms_cat_expense_load_without_flood: string;
    rms_cat_expense_load_without_flood_final: string;
    rms_cat_loss_cost_final: string;
    rms_cat_multiple_with_loss_adjustment: string;
    rms_cat_net_premium: string;
    rms_cat_net_premium_final: string;
    rms_cat_net_premium_original: string;
    rms_cat_net_premium_override: string;
    rms_cat_net_premium_without_flood: string;
    rms_cat_net_premium_without_flood_final: string;
    rms_cat_net_premium_without_flood_override: string;
    rms_modeled_cat_premium: string;
    rms_modeled_cat_premium_final: string | null;
    rms_oep_200: string;
    rms_oep_250: string;
    rms_oep_1000: string;
    rms_pct_of_technical: number;
    rms_pee_adjusted_limits_final: string;
    rms_pee_included_limits_final: string;
    rms_pee_interim_data: unknown;
    rms_pee_limits_final: string;
    rms_property_enhancement_loss_cost: string;
    rms_property_enhancement_loss_cost_adjusted_limits: string;
    rms_property_enhancement_loss_cost_final: string;
    rms_property_enhancement_loss_cost_override: string;
    rms_technical_aop_premium: number | null;
    rms_technical_premium_before_tf: number | null;
    rms_total_aop_loss_cost_adj_for_participation: string;
    rms_total_aop_loss_cost_adj_for_participation_final: string;
    rms_total_aop_loss_cost_full_participation: string;
    rms_total_aop_loss_cost_full_participation_final: string;
    rms_total_aop_premium: string;
    rms_total_aop_premium_final: string;
    rms_total_cat_premium: string;
    rms_total_cat_premium_final: string | null;
    rms_total_earthquake_cat_premium_final: string | null;
    rms_total_wind_cat_premium_final: string | null;
    rms_total_expected_loss: string;
    rms_total_premium: string;
    rms_total_premium_original: string;
    seasonality_factor: string;
    spread_of_risk_credit: string;
    spread_of_risk_credit_final: string;
    spread_of_risk_credit_override: string;
    stamping_fees: string;
    stamping_fees_final: string;
    stamping_fees_override: string;
    surplus_lines_taxes: string;
    surplus_lines_taxes_final: string;
    surplus_lines_taxes_override: string;
    target_minimum_charged_premium: string;
    target_minimum_charged_premium_net_of_agent_commission: number | null;
    terror_expense_load: string;
    terror_expense_load_final: string;
    terror_loss_cost: string;
    terror_loss_cost_final: string;
    terror_loss_cost_model: string;
    terror_loss_cost_override: string;
    terror_premium: string;
    terror_premium_final: string;
    terror_premium_override: string;
    terror_premium_adj_for_participation_final: string;
    tiv: string;
    tmcp_multiple: string;
    total_aop_loss_cost: string;
    total_aop_loss_cost_adj_for_participation: string;
    total_aop_loss_cost_adj_for_participation_before_eb: string;
    total_aop_loss_cost_adj_for_participation_before_eb_final: string;
    total_aop_loss_cost_adj_for_participation_final: string;
    total_aop_loss_cost_final: string;
    total_aop_loss_cost_full_participation: string;
    total_aop_loss_cost_full_participation_before_eb: string;
    total_aop_loss_cost_full_participation_before_eb_final: string;
    total_aop_loss_cost_full_participation_final: string;
    total_aop_premium: string;
    total_aop_premium_after_overrides: string;
    total_aop_premium_before_eb: string;
    total_aop_premium_before_eb_final: string;
    total_aop_premium_final: string;
    total_bi_limit: string;
    total_bi_loss_cost: string;
    total_building_loss_cost: string;
    total_building_loss_cost_final: string;
    total_building_loss_cost_override: string;
    total_building_premium_final: number | null;
    total_building_tiv: string;
    total_pricing_tiv: string; // includes ventus_pricing_override
    total_cat_premium_after_overrides: string;
    total_loss_cost: string;
    total_ord_law_a_limits: string;
    total_ord_law_a_loss_costs: string;
    total_premium_final: string;
    total_premium_override: string;
    total_taxes_fees_expenses: string;
    underwriter: number;
    ventus_commission_rate: string;
    weighted_average_age_of_build: string;
}

export interface BuildingPremium {
    description: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    county: string;
    pretty_address: string;
    tiv: number;
}

export interface BuildingPremiumResultType {
    results: any[];
}

export interface MarginPMLResultType {
    air_marginal_aep_200: number | null;
    air_marginal_aep_250: number | null;
    air_marginal_aep_1000: number | null;
}

export enum LayerTypes {
    GROUNDUP = 'G',
    PRIMARY = 'P',
    EXCESS = 'E',
}

export enum DeductibleFormat {
    PERCENTAGE = 'PERCENTAGE',
    DOLLAR_AMOUNT = 'DOLLAR_AMOUNT',
}

export enum CatWindDeductible {
    NONE = 'NONE',
    WIND_HAIL = 'WIND_HAIL',
    NAMED_STORM = 'NAMED_STORM',
    HURRICANE = 'HURRICANE',
}

export type CattoolsExportParams = {
    business_unit: string;
    cat_model_type: string;
    cat_view_type: string;
    in_force_date: string;
    policy_numbers: string;
    inception_date_before: string;
    inception_date_after: string;
    expiration_date_before: string;
    expiration_date_after: string;
    carrier_id: number;
    lob_format: string;
};

export type BuildingCoverageType = {
    id: number;
    location_code: string;
    building: Pick<BuildingDataType, 'id' | 'street' | 'city' | 'state' | 'county' | 'zipcode'> & { description: string };
};

export type PolicyCoverageType = {
    id: number;

    is_sprinkler_leakage_included: boolean;
    aop: boolean;
    agent_commission_rate: string | null;
    air_broker_cat_aal_override: string | null;
    air_broker_cat_loss_cost_override: null;
    air_cat_aal_override: string | null;
    air_cat_loss_cost_override: string | null;
    air_cat_net_premium_override: string | null;
    air_cat_net_premium_without_flood_override: string | null;
    air_property_enhancement_loss_cost_override: string | null;
    air_total_premium_original_override: string | null;
    aop_deductible: number;
    applied_policy_forms: string;
    appurtenant_structures_claim_valuation: string;
    appurtenant_structures_coinsurance: number;
    assessments_override: string | null;
    assigned_claims_adjuster_company_name: string;
    assigned_claims_adjuster_email: string;
    assigned_claims_adjuster_name: string;
    assigned_claims_adjuster_phone: string;
    assigned_claims_adjuster_title: string;
    bi_monthly_limitation: string;
    bi_type: string;
    building_coverages: BuildingCoverageType[];
    building_limit_claim_valuation: string;
    building_limit_coinsurance: number;
    bus_interruption_deduct: number;
    business_interruption_includes_extra_expense: boolean;
    business_interruption_includes_rental_income: boolean;
    business_strategy_override: string | null;
    charged_premium_before_tf: string | null;
    contents_limit_claim_valuation: string;
    contents_limit_coinsurance: number;
    coverage_form: string;
    created: string;
    cw_agg_deductible: boolean;
    cw_deductible: string;
    cw_deductible_appl: string;
    cw_deductible_dollar_amt: number;
    cw_deductible_fmt: DeductibleFormat;
    cw_deductible_pct_amt: number;
    cw_min_deductible: boolean;
    cw_min_deductible_dollar_amt: number;
    cw_min_deductible_fmt: string;
    cw_min_deductible_pct_amt: number;
    cw_secondary_deductible_dollar_amt: number;
    cw_secondary_deductible_fmt: DeductibleFormat;
    cw_secondary_deductible_pct_amt: number;
    cw_max_deductible: boolean;
    cw_max_deductible_dollar_amt: number;
    data_entry_validation_issues: string | null;
    default_agent_commission_rate: string | null;
    default_ventus_commission_rate: string;
    earthquake_deductible_dollar_amt: number;
    earthquake_deductible_fmt: string;
    earthquake_deductible_pct_amt: number;
    earthquake_limit: string | null;
    eb_sublimit_data_restoration: number;
    eb_sublimit_dicc: number;
    eb_sublimit_drying_out: number;
    eb_sublimit_electronic_vandalism: number;
    eb_sublimit_expediting_expenses: number;
    eb_sublimit_fungus_rot: number;
    eb_sublimit_hazardous_substances: number;
    eb_sublimit_off_premises_coverage: number;
    eb_sublimit_spoilage: number;
    effective_date: string;
    electronic_data_processing_claim_valuation: string;
    electronic_data_processing_coinsurance: number;
    equipment_breakdown: boolean;
    equipment_breakdown_external_premium_override: string | null;
    equipment_breakdown_loss_cost_override: string | null;
    evacuation_expense_endorsement: boolean;
    evacuation_expense_endorsement_aggregate_limit: string | null;
    evacuation_expense_endorsement_per_occurrence_limit: string | null;
    expiration_date: string;
    flood_1_bi_waiting_period: string | null;
    flood_1_deductible: string | null;
    flood_1_limit: string | null;
    flood_2_bi_waiting_period: string | null;
    flood_2_deductible: string | null;
    flood_2_limit: string | null;
    flood_3_bi_waiting_period: string | null;
    flood_3_deductible: string | null;
    flood_3_limit: string | null;
    flood_4_bi_waiting_period: string | null;
    flood_4_deductible: string | null;
    flood_4_limit: string | null;
    flood_policy_per_occurrence_deductible: string | null;
    flood_policy_per_occurrence_limit: string | null;
    flood_policy_term_aggregate_limit: string | null;
    form: string;
    gross_amount_subject_account_rate: number | null;
    gross_tiv_account_rate: number | null;
    is_ground_up_sublimit_enabled: boolean;
    ground_up_sublimit: number;
    inland_flood_loss_cost_override: string | null;
    inspection_fees_override: string | null;
    is_earthquake_included: boolean;
    is_earthquake_minimum_occurrence_applied: boolean;
    is_earthquake_maximum_occurrence_applied: boolean;
    earthquake_min_occurrence_deductible_dollar_amt: number | null;
    earthquake_max_occurrence_deductible_dollar_amt: number | null;
    is_equipment_breakdown_referral_flagged_by_user: boolean;
    is_flood_1_included: boolean;
    is_flood_2_included: boolean;
    is_flood_3_included: boolean;
    is_flood_4_included: boolean;
    is_flood_included: boolean;
    is_allow_prorata_wind_mep: boolean;
    is_allow_prorata_wind_mep_override: boolean;
    is_increased_period_of_restoration: boolean;
    is_ord_law_b_limit_included: boolean;
    is_ord_law_blanket_bc_limit_included: boolean;
    is_ord_law_c_limit_included: boolean;
    is_ordinary_payroll_exclusion_included: boolean;
    is_pee_standard_limits_included: boolean;
    is_storm_surge_included_in_wind: boolean;
    is_bi_deductible_included_in_wind: boolean;
    layered_pricing_factor_override: string | null;
    limit_of_insurance_credit_override: string | null;
    loss_history_credit_override: string | null;
    manually_applied_policy_forms: unknown;
    manuscript_endorsement_file: unknown[];
    manuscript_form_file: string | null;
    margin_percentage: number;
    min_sinkhole_deductible: number;
    modeling_fee_override: string | null;
    modified: string;
    occurrence_attachment: number;
    occurrence_limit: number;
    occurrence_participation: number;
    occurrence_participation_dollar_amt: number;
    occurrence_participation_fmt: DeductibleFormatsType;
    attachment_layer_name: string | null;
    ord_law_a_limit_uses_building_limit: boolean;
    ord_law_b_limit_dollar_amt: string;
    ord_law_b_limit_fmt: DeductibleFormat;
    ord_law_b_limit_max: string | null;
    ord_law_b_limit_pct_amt: string;
    ord_law_b_limit_per_occurrence_limit: string | null;
    ord_law_bc_coverage_limit_type: string;
    ord_law_blanket_bc_limit_dollar_amt: string;
    ord_law_blanket_bc_limit_fmt: DeductibleFormat;
    ord_law_blanket_bc_limit_max: string | null;
    ord_law_blanket_bc_limit_pct_amt: string;
    ord_law_blanket_bc_limit_per_occurrence_limit: string | null;
    ord_law_c_limit_dollar_amt: string;
    ord_law_c_limit_fmt: DeductibleFormat;
    ord_law_c_limit_max: string | null;
    ord_law_c_limit_pct_amt: string;
    ord_law_c_limit_per_occurrence_limit: string | null;
    ordinary_payroll_covered_days: string | null;
    ordinary_payroll_exclusions: string;
    other_debits_credits_override: string | null;
    policy_is_layered: boolean;
    previous_policy_forms: unknown;
    prop_enhancement_endorsement: boolean;
    property_enhancement_loss_cost_override: string | null;
    property_of_others_claim_valuation: string;
    property_of_others_coinsurance: number;
    quote: number;
    rms_broker_cat_aal_override: string | null;
    rms_cat_aal_override: string | null;
    rms_cat_net_premium_override: string | null;
    rms_cat_net_premium_without_flood_override: string | null;
    rms_property_enhancement_loss_cost_override: string | null;
    rms_total_premium_original_override: string | null;
    sinkhole: boolean;
    spread_of_risk_credit_override: string | null;
    stamping_fees_override: string | null;
    stock_limit_claim_valuation: string;
    stock_limit_coinsurance: number;
    submission: number;
    suggested_policy_forms: Record<string, boolean>;
    surplus_lines_taxes_override: string | null;
    tenant_improvements_and_betterments_claim_valuation: string;
    tenant_improvements_and_betterments_coinsurance: number;
    terror: boolean;
    terror_loss_cost_override: string | null;
    terror_premium_override: string | null;
    theft_exclusion: boolean;
    theft_of_metal_exclusion: boolean;
    total_building_loss_cost_override: string | null;
    total_premium_override: string | null;
    use_assigned_claims_adjuster: boolean;
    use_itv_adjustment: boolean;
    ventus_commission_rate: string;
    version: number;
    water_damage_deductible: string | null;
    wind_or_hail_excluded: boolean;
    ventus_share_of_wind_sublimit: string;
} & PeeOverridesType &
    PeeIncludesType;

export type PeeIncludesType = Record<`pee_included_${(typeof PEE_FIELDS)[number]}`, string>;
export type PeeOverridesType = Record<`pee_override_${(typeof PEE_FIELDS)[number]}`, number>;

export type PolicyTermsResult = {
    id: number;
    policy_coverage_id: number;
    quote_id: number;
    submission_id: number;
    group_overview: boolean;
    quote_description: string;
    effective_date: string;
    expiration_date: string;
    coverage_form: string;
    form: string;
    ventus_commission_rate: string;
    agent_commission_rate: string;
    group_shared_and_layered: boolean;
    occurrence_participation_fmt: DeductibleFormat;
    occurrence_participation: number;
    occurrence_participation_dollar_amt: string;
    policy_is_layered: boolean;
    occurrence_limit: number;
    occurrence_liability_limit_included: boolean;
    occurrence_attachment: number;
    group_wind: boolean;
    wind_or_hail_excluded: boolean;
    ground_up_sublimit: string;
    wind_share_of_sublimit_label: boolean;
    cw_deductible: string;
    cw_deductible_fmt: DeductibleFormat;
    cw_deductible_dollar_amt: number;
    cw_deductible_pct_amt: number;
    cw_deductible_appl: string;
    cw_min_deductible: boolean;
    cw_min_deductible_dollar_amt: number;
    cw_max_deductible: boolean;
    cw_max_deductible_dollar_amt: number;
    cw_agg_deductible: boolean;
    cw_secondary_deductible_fmt: DeductibleFormat;
    cw_secondary_deductible_dollar_amt: number;
    cw_secondary_deductible_pct_amt: number;
    wdbd_included: boolean;
    is_storm_surge_included_in_wind: boolean;
    is_bi_deductible_included_in_wind: boolean;
    group_pro_rata_wind_mep: boolean;
    is_allow_prorata_wind_mep: boolean;
    is_allow_prorata_wind_mep_override: boolean;
    group_flood: boolean;
    is_flood_included: boolean;
    is_flood_1_included: boolean;
    is_flood_2_included: boolean;
    is_flood_3_included: boolean;
    is_flood_4_included: boolean;
    flood_1_limit: string;
    flood_1_deductible: string;
    flood_1_bi_waiting_period: string;
    flood_2_limit: string;
    flood_2_deductible: string;
    flood_2_bi_waiting_period: string;
    flood_3_limit: string;
    flood_3_deductible: string;
    flood_3_bi_waiting_period: string;
    flood_4_limit: string;
    flood_4_deductible: string;
    flood_4_bi_waiting_period: string;
    flood_policy_per_occurrence_limit: string;
    flood_policy_term_aggregate_limit: string;
    flood_policy_per_occurrence_deductible: string;
    group_earthquake: boolean;
    is_earthquake_included: boolean;
    earthquake_deductible_basis: string;
    earthquake_fire_following: number;
    earthquake_limit: string;
    earthquake_deductible_fmt: DeductibleFormat;
    earthquake_deductible_dollar_amt: number;
    earthquake_deductible_pct_amt: number;
    earthquake_share_of_sublimit_label: boolean;
    is_earthquake_minimum_occurrence_applied: boolean;
    is_earthquake_maximum_occurrence_applied: boolean;
    earthquake_min_occurrence_deductible_dollar_amt: string;
    earthquake_max_occurrence_deductible_dollar_amt: string;
    is_sprinkler_leakage_included: boolean;
    group_aop: boolean;
    aop: boolean;
    aop_deductible: number;
    water_damage_deductible: string;
    group_business_interruption: boolean;
    bi_type: string;
    business_interruption_includes_extra_expense: boolean;
    business_interruption_includes_rental_income: boolean;
    bi_monthly_limitation: string;
    bus_interruption_deduct: number;
    group_claims_valuation_and_coinsurance: boolean;
    building_limit_claim_valuation: string;
    building_limit_coinsurance: number;
    appurtenant_structures_claim_valuation: string;
    appurtenant_structures_coinsurance: number;
    contents_limit_claim_valuation: string;
    contents_limit_coinsurance: number;
    stock_limit_claim_valuation: string;
    stock_limit_coinsurance: number;
    tenant_improvements_and_betterments_claim_valuation: string;
    tenant_improvements_and_betterments_coinsurance: number;
    property_of_others_claim_valuation: string;
    property_of_others_coinsurance: number;
    electronic_data_processing_claim_valuation: string;
    electronic_data_processing_coinsurance: number;
    group_margin: boolean;
    margin_percentage: number;
    group_terror: boolean;
    terror: boolean;
    group_equipment_breakdown: boolean;
    equipment_breakdown: boolean;
    eb_sublimit_spoilage: number;
    eb_sublimit_expediting_expenses: number;
    eb_sublimit_drying_out: number;
    eb_sublimit_hazardous_substances: number;
    eb_sublimit_data_restoration: number;
    eb_sublimit_dicc: number;
    eb_sublimit_off_premises_coverage: number;
    eb_sublimit_electronic_vandalism: number;
    is_equipment_breakdown_referral_flagged_by_user: boolean;
    group_ordinance_or_law: boolean;
    ord_law_a_limit_uses_building_limit: boolean;
    is_increased_period_of_restoration: boolean;
    is_ord_law_b_limit_included: boolean;
    ord_law_b_limit_fmt: DeductibleFormat;
    ord_law_b_limit_dollar_amt: string;
    ord_law_b_limit_pct_amt: string;
    ord_law_b_limit_max: string;
    ord_law_b_limit_per_occurrence_limit: string;
    is_ord_law_c_limit_included: boolean;
    ord_law_c_limit_fmt: DeductibleFormat;
    ord_law_c_limit_dollar_amt: string;
    ord_law_c_limit_pct_amt: string;
    ord_law_c_limit_max: string;
    ord_law_c_limit_per_occurrence_limit: string;
    is_ord_law_blanket_bc_limit_included: boolean;
    ord_law_blanket_bc_limit_fmt: DeductibleFormat;
    ord_law_blanket_bc_limit_dollar_amt: string;
    ord_law_blanket_bc_limit_pct_amt: string;
    ord_law_blanket_bc_limit_max: string;
    ord_law_blanket_bc_limit_per_occurrence_limit: string;
    group_sinkhole: boolean;
    sinkhole: boolean;
    min_sinkhole_deductible: number;
    group_property_enhancement_endorsement: boolean;
    prop_enhancement_endorsement: boolean;
    is_pee_standard_limits_included: boolean;
    evacuation_expense_endorsement: boolean;
    evacuation_expense_endorsement_per_occurrence_limit: string;
    evacuation_expense_endorsement_aggregate_limit: string;
    pee_override_accounts_receivable: number;
    pee_override_asbestos_removal: number;
    pee_override_builders_risks: number;
    pee_override_builders_risks_soft_costs: number;
    pee_override_contingent_time_element: number;
    pee_override_debris_removal_additional_limit: number;
    pee_override_debris_removal_maximum: number;
    pee_override_debris_removal_percent_loss: number;
    pee_override_electronic_data_and_media: number;
    pee_override_emergency_removal_expense: number;
    pee_override_errors_or_omissions: number;
    pee_override_extended_period_of_bi_indemnity: number;
    pee_override_extra_expense: number;
    pee_override_fine_arts: number;
    pee_override_fire_brigade_charges: number;
    pee_override_fraud_and_deceit: number;
    pee_override_fungus_aggregate: number;
    pee_override_fungus_per_occurrence: number;
    pee_override_green_upgrades: number;
    pee_override_ingress_distance: number;
    pee_override_ingress_maximum: number;
    pee_override_ingress_weeks: number;
    pee_override_interruption_by_authority_distance: number;
    pee_override_interruption_by_authority_maximum: number;
    pee_override_interruption_by_authority_weeks: number;
    pee_override_leased_equipment_aggregate_limit: number;
    pee_override_leased_equipment_per_item_limit: number;
    pee_override_leasehold_interest: number;
    pee_override_limited_pollution_coverage: number;
    pee_override_lock_replacement: number;
    pee_override_misc_unnamed_locations: number;
    pee_override_newly_acquired_property_days: number;
    pee_override_newly_acquired_property_maximum: number;
    pee_override_ordinary_payroll: number;
    pee_override_personal_property_of_others: number;
    pee_override_plants_aggregate_limit: number;
    pee_override_plants_per_item_limit: number;
    pee_override_preservation_of_property_days: number;
    pee_override_professional_fees: number;
    pee_override_property_in_course_of_construction: number;
    pee_override_recharging_fire_equipment: number;
    pee_override_reclaiming_land_improvements: number;
    pee_override_reward_reimbursement: number;
    pee_override_royalties: number;
    pee_override_service_interruption_bi: number;
    pee_override_service_interruption_combined: number;
    pee_override_service_interruption_direct_damage: number;
    pee_override_sewer: number;
    pee_override_transit: number;
    pee_override_valuable_papers: number;
    pee_override_wind_driven_precipitation: number;
};

export type PolicyTermsGlossaryResult = {
    [key in keyof PolicyTermsResult]: TenantGlossaryItemType;
};

export type BuildingTermsResult = {
    id: number;
    building_coverage_id: number;
    quote_id: number;
    submission_id: number;
    quote_description: string;

    // building overview
    is_included_in_quote: boolean;
    location_tiv: number;
    pricing_tiv: number;

    // building and coverage limits
    building_limit: number;
    sov_value: number;
    external_valuation_amount: number;
    structure_value: number;
    bi_limit: number;
    business_personal_property: number;
    contents_limit: number;
    stock_limit: number;
    property_of_others_limit: number;
    electronic_data_processing_limit: number;
    tenant_improvements_and_betterments_limit: number;

    // wind
    wind_or_hail_excluded: boolean;

    // flood
    is_flood_included: boolean;

    // EQ
    is_policy_earthquake_included: boolean;
    policy_earthquake_deductible_fmt: DeductibleFormat;
    quake_exclusion: boolean;
    earthquake_deductible_basis: string;
    earthquake_limit: string;
    earthquake_deductible_fmt: DeductibleFormat;
    earthquake_deductible_dollar_amt: number;
    earthquake_deductible_pct_amt: number;

    // AOP
    is_policy_aop_included: boolean;
    aop: boolean;
    aop_deductible: number;

    // terror
    terror: boolean;

    // EQUIPMENT_BREAKDOWN
    equipment_breakdown: boolean;
    is_policy_equipment_breakdown_included: boolean;
};

export type BuildingTermsGlossaryResult = {
    [key in keyof BuildingTermsResult]: TenantGlossaryItemType;
};

export type BuildingGroupTermsResult = {
    id: number;
    policy_coverage_id: number;
    quote_id: number;
    submission_id: number;
    quote_description: string;

    // wind
    group_wind: boolean;
    is_wind_included: boolean;
    wind_ground_up_sublimit: number;
    wind_share_of_sublimit_label: true;
    wind_deductible: string;
    wind_deductible_fmt: DeductibleFormat;
    wind_deductible_dollar_amt: number;
    wind_deductible_pct_amt: number;
    wind_deductible_basis: string;
    wind_min_deductible: boolean;
    wind_min_deductible_dollar_amt: number;
    wind_max_deductible: boolean;
    wind_max_deductible_dollar_amt: number;
    wind_agg_deductible: boolean;
    wind_aow_deductible_fmt: null;
    wind_aow_deductible_dollar_amt: number;
    wind_aow_deductible_pct_amt: number;
    is_storm_surge_included_in_wind: boolean;
    is_bi_deductible_included_in_wind: boolean;

    // earthquake
    group_earthquake: boolean;
    is_earthquake_included: boolean;
    earthquake_deductible_basis: string;
    earthquake_ground_up_sublimit: number;
    earthquake_deductible_fmt: DeductibleFormat;
    earthquake_deductible_dollar_amt: number;
    earthquake_deductible_pct_amt: number;
    earthquake_share_of_sublimit_label: true;
    is_earthquake_minimum_occurrence_applied: boolean;
    is_earthquake_maximum_occurrence_applied: boolean;
    earthquake_min_occurrence_deductible_dollar_amt: number;
    earthquake_max_occurrence_deductible_dollar_amt: number;
    earthquake_fire_following: number;
    is_sprinkler_leakage_included: false;
};

export type BuildingGroupTermsGlossaryResult = {
    [key in keyof BuildingGroupTermsResult]: TenantGlossaryItemType;
};

export type PolicyFormGroups = {
    ADDITIONAL_INSUREDS_AND_INTERESTS: 'ADDITIONAL INSUREDS AND INTERESTS';
    ADDITIONAL_INTERESTS: 'ADDITIONAL INTERESTS';
    BASIC_COVERAGE_FORMS: 'BASIC COVERAGE FORMS';
    BUSINESS_INTERRUPTION: 'BUSINESS INTERRUPTION';
    CATASTROPHE: 'CATASTROPHE';
    CONDITIONS_RELATED: 'CONDITIONS RELATED';
    COVERAGE_RELATED: 'COVERAGE RELATED';
    GENERAL_POLICY_FORMS: 'GENERAL POLICY FORMS';
    INACTIVE_FORMS: 'INACTIVE FORMS';
    MANUSCRIPT: 'MANUSCRIPT FORMS';
    MISCELLANEOUS_COVERAGE: 'MISCELLANEOUS COVERAGE';
    MISCELLANEOUS_EXCLUSIONS: 'MISCELLANEOUS EXCLUSIONS';
    NOTICES: 'NOTICES';
    STATE_SPECIFIC_FORMS: 'STATE SPECIFIC FORMS';
    TERRORISM: 'TERRORISM FORMS';
    VENTUS_LAYERED_FORMS_PRIMARY: 'LAYERED FORMS';
};

export type PolicyFormsResult = {
    policy_forms: {
        checkboxes: PolicyFormCheckbox[];
        checkboxes_by_group: { [key in keyof PolicyFormGroups]: string[] };
        checkboxes_by_field_id: Record<string, PolicyFormCheckbox>;
        group_order: (keyof PolicyFormGroups)[];
        groups: PolicyFormGroups;
        has_manual_overrides: boolean;
        is_manuscript_enabled: boolean;
        manuscript_endorsement_file: unknown[];
        manuscript_form_file: string | null;
        textboxes: []; // yeah, it's just a hardcoded empty array
    };
};

export type DefaultPolicyDocEmailInfoResult = {
    send_to: string[];
    subject: string;
    message_body: string;
};

export type GenerateLossRunReportResult = {
    account_id: number;
    created: string;
    document_id: null;
    errors: null;
    fail_message: null;
    failed: boolean;
    id: number;
    in_progress: boolean;
    key_id: number;
    last_updated: string;
    links: { document: 'document/'; submission: 'submission/'; new_submission: 'new_submission/'; quote: 'quote/'; account: 'account/' };
    modified: string;
    new_submission_id: null;
    percent_complete: number;
    queued: true;
    quote_id: null;
    received_result_time: null;
    sort_by_time: string;
    status: keyof typeof BACKGROUND_TASK_STATUSES;
    status_display: string;
    submission__insured__name: string;
    submission__site_id: number;
    submission_id: number;
    submitter__full_name: string;
    submitter_id: number;
    succeeded: boolean;
    task_type: string;
    task_type_display: string;
    title: string;
    total_duration: number;
};

export type PolicyFormCheckbox = {
    active_description: string;
    applied: boolean;
    deactivated_on: string | null;
    disabled: boolean;
    display_number: number;
    endorsement_package: boolean;
    endorsement_package_enabled: boolean;
    field: string;
    form_number: string;
    group_id: string;
    is_set_via_decision_rule: boolean;
    label: string;
    manually_applied: Record<string, string> | null;
    suggested: boolean | null;
    is_existing_latex_form: boolean;
    _actions: {
        follow_suggested: { enabled: boolean; visible: boolean };
        clear_override: { enabled: boolean; visible: boolean };
        set_override: { enabled: boolean; visible: boolean };
    };
};

export type QuoteResult = Pick<
    QuoteType,
    | 'id'
    | 'created'
    | 'date_sent'
    | 'description'
    | 'final_premium_id'
    | 'last_cat_model_request_sent_on'
    | 'modified'
    | 'policy_coverage_id'
    | 'is_carrier_layered'
    | 'split_point'
    | 'layer_1_premium'
    | 'layer_2_premium'
>;

export type ReferralHistoryResult = {
    approval_hash: string;
    approved: ReferralDataType[];
    is_unrequested: boolean;
    requires_approval: ReferralDataType[];
};

type PolicyCoverageSetOverrides = [
    'agent_commission_rate',
    'inspection_fees_override',
    'charged_premium_before_tf',
    'gross_amount_subject_account_rate',
    'gross_tiv_account_rate',
    'air_broker_cat_aal_override',
    'air_cat_aal_override',
    'equipment_breakdown_external_premium_override',
    'rms_broker_cat_aal_override',
    'rms_cat_aal_override',
    'terror_premium_override'
];
export type SetOverridesArgs = Pick<PolicyCoverageType, 'equipment_breakdown' | 'terror'> &
    Pick<QuoteType, 'split_point' | 'layer_1_premium' | 'layer_2_premium'> &
    Pick<PolicyCoverageType, PolicyCoverageSetOverrides[number]> & {
        carrier_participations: FinalCarrierData[];
    };

export type ExpiringTabResponseType = {
    quote: QuoteResult;
    atr: Record<string, any>;
};

export type MetricsResult = Record<string, any>;

export type RegionalCATSummaryChoiceResultType = {
    regions: [string, string][];
    perils: [string, string][];
    quotes: [string, string][];
};

export type SuccessResponse = {
    quote: QuoteResult;
};

export type RepriceValidations = {
    CAN_BE_BOUND: string[];
    CAN_BE_QUOTED: string[];
    CAN_PREVIEW_QUOTE: string[];
    CAN_PRICE_AOP: string[];
    CAN_PRICE_CAT: string[];
    CAN_SYNC_TO_ARCH: string[];
    CAN_ISSUE_POLICY: string[];
};

export type RepriceResult = {
    reprice: {
        is_valid: boolean;
        validation_messages: ValidationsResult;
        gross_amount_subject_account_rate: number | null;
        gross_tiv_account_rate: number | null;
    };
    is_allowed_to_quote: {
        is_allowed: boolean;
    };
};

export type SubmissionResult = {
    submission: Omit<SubmissionDataType, 'quotes'> & { quotes: QuoteType[] };
};

export type SubmissionOverviewResult = {
    account_broker_email: string;
    account_broker_id: number;
    account_broker_address1: string;
    account_broker_address2: string;
    account_broker_phone_number: string;
    aop_deductible: number;
    aow_deductible: number;
    assigned_claims_adjuster_company_name: string;
    assigned_claims_adjuster_email: string;
    assigned_claims_adjuster_name: string;
    assigned_claims_adjuster_phone: string;
    assigned_claims_adjuster_title: string;
    brokerage_company_name: string;
    brokerage_office_fein: string;
    brokerage_office_id: number;
    brokerage_office_location: string;
    business_segment: string; // TODO: Add segment type
    new_business_segment: string;
    business_strategy: string; // TODO: Add strategy type
    // carrier_pools: number[]; // TODO: Do we need this? It messes up the form types if included
    carrier_pools_display: string;
    charged_terror_premium: CurrencyType;
    date_bound: string;
    date_received: string;
    effective_date: string;
    equipment_breakdown: 'Yes' | 'No';
    expiration_date: string;
    home_state: string; // TODO: state type
    id: number;
    insured_business_form: string;
    insured_city: string;
    insured_contact_email: string;
    insured_contact_name: string;
    insured_contact_phone: string;
    insured_description_of_primary_operations: string;
    insured_expiring_carrier: string;
    insured_expiring_premium: CurrencyType;
    insured_in_or_filed_bankruptcy: boolean;
    insured_loss_history: number;
    insured_loss_ratio_years: number;
    insured_name: string;
    insured_owing_back_taxes: boolean;
    insured_state: string;
    insured_street: string;
    insured_zipcode: string;
    is_renewal: boolean;
    licensed_broker_id: number;
    licensed_broker_address1: string;
    licensed_broker_address2: string;
    max_flood_limit: number;
    ofac_status: string;
    parent_brokerage_company_name: string;
    policy_issuance_date: string;
    policy_number: string;
    primary_policy_coverage_id: number;
    primary_quote_bi_pct: number;
    primary_quote_data_completeness_index: number;
    primary_quote_data_completeness_index_rating: string;
    primary_quote_date_sent: string;
    primary_quote_id: number;
    primary_quote_itv_account_quality_index: number;
    primary_quote_itv_account_quality_index_rating: string;
    primary_quote_max_building_tiv: CurrencyType;
    primary_quote_number_of_buildings: number;
    primary_quote_total_bi_limit: CurrencyType;
    primary_quote_total_building_tiv: CurrencyType;
    renewal_of_id: number;
    renewal_status: string;
    rewrite_of_policy_number: string;
    rewritten_by_policy_number: string;
    sla_number: string;
    status: keyof typeof SUBMISSION_STATUS;
    submission_id: number;
    support_contact_email: string;
    support_contact_id: number;
    support_contact_name: string;
    support_contact_phone_number: string;
    target_effective_date: string;
    target_expiration_date: string;
    target_premium: CurrencyType;
    target_quote_date: string;
    underwriter_id: number;
    arch_underwriter_id: number;
    use_assigned_claims_adjuster: false;
    water_damage_deductible: number;
    wdr_limit: number;
};

export type SubmissionOverviewGlossaryResult = {
    [key in keyof SubmissionOverviewResult]: TenantGlossaryItemType;
};

export type ValidationsResult = {
    ERROR: RepriceValidations;
    WARN: RepriceValidations;
    detailObjs: {
        [key: string]: {
            affects: string;
            coverage_option_id: number;
            description: string;
            field: string;
            message: string;
            submission_id: number;
            verbose_name: string;
        };
    };
};

export type ValidationError = {
    error_code: 'INVALID_COVERAGE_OPTION' | 'INVALID_ACCOUNT_TRANSACTION' | 'INVALID_SUBMISSION';
    error_detail: {
        validation_type: string;
        submission_id: number;
        account_transaction_id?: number;
        coverage_option_id?: number;
    };
    error_message: string;
    error_detail_list: string[];
};

export type HazardHubDataRequest = {
    buildingId: number;
};

export type HazardHubDataResult = {
    ppc: string;
    sinkhole_rating: string;
    fema_flood_zone: string;
    county_tier: number;
    crime_score: string;
    crime_text: string;
    crime_value: number;
    distance_to_coast_value: number;
    distance_to_coast_units: string;
};

export type ComparableSubmissionsOptions = {
    submissionId: number;
    quoteId: number;
    effective_date_start?: string;
    effective_date_end?: string;
    filter_layered?: boolean;
    filter_wind_deductible_type?: boolean;
    filter_wind_deductible_amt?: boolean;
    filter_occupancy?: boolean;
    filter_construction?: boolean;
    filter_zip?: boolean;
    filter_county?: boolean;
    filter_state?: boolean;
    filter_region?: boolean;
};

export type ComparableSubmissionsResult = {
    OUTSTANDING: ComparableSubmissionsStats;
    NOT_TAKEN_UP: ComparableSubmissionsStats;
    BOUND: ComparableSubmissionsStats;
    ALL: ComparableSubmissionsStats;
};

export type ComparableSubmissionsStats = {
    average: number;
    minimum: number;
    maximum: number;
    first_quartile: number;
    third_quartile: number;
    count: number;
};
export type TenantGlossaryItemType = {
    active?: boolean;
    field_format?: { subtype?: 'percentage' | 'currency'; nocommas?: boolean; decimals?: number; stored_as_ratio?: boolean };
    label?: string;
    choices?: Choice<unknown>[];
    tooltip?: string;
    inverse?: boolean;
    editable?: boolean;
} & GlossaryItemType;

export type SedgwickClaimResults = {
    total_claims: number;
    total_loss_paid: number;
    total_loss_reserve: number;
    total_expense_paid: number;
    total_expense_reserve: number;
    total_subro_recovery: number;
    total_incurred: number;
    inception_date: string;
    expiration_date: string;
    policy_claims: PolicyClaim[];
};

export type PolicyClaim = {
    // TODO: total value fields
    policy_number: string;
    inception_date: string;
    expiration_date: string;
    incurred: number;
    claims: ClaimType[];
};

export type ClaimType = {
    claim_number: string;
};
