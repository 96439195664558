import { refreshTransaction } from './EndorsementActions';
import { getAccountDataFromServer, updateDocumentData, deleteDocumentFromSubmission, addDocumentToSubmission } from './SubmissionActions';
import { clearRowValidations, setRowToDirty, refreshData, ensureRowExists } from '@archinsurance-viki/property-jslib/src/actions/TableActions';
import { PAGED_TABLES } from '../constants/PagedTableConfigs';
import AppConstants from '@archinsurance-viki/property-jslib/src/constants/AppConstants';
import { getApiParamsKey } from '@archinsurance-viki/property-jslib/src/utils/tables/table-helper';
import { AppDispatch, RootState } from '../store';

export function handleTransactionRefresh(data: Record<string, any>) {
    return (dispatch: AppDispatch, getState: () => { transactions: { rowData: any } }) => {
        const transactionId = data.transaction_id;
        let {
            transactions: { rowData },
        } = getState();
        if (!(transactionId in rowData)) {
            console.warn('skipping transaction refresh push TID:', transactionId);
            return;
        }
        dispatch(refreshTransaction(transactionId));
    };
}

export function handleAccountRefresh(data: Record<string, any>) {
    return (dispatch: AppDispatch, getState: () => { accountsState: { accounts: any } }) => {
        const accountId = data.account_id;
        let {
            accountsState: { accounts },
        } = getState();
        if (!(accountId in accounts)) {
            console.warn('skipping account refresh push AID:', accountId);
            return;
        }
        dispatch(getAccountDataFromServer(accountId));
    };
}

export function handleAccountRevalidation(data: Record<string, any>) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const submissionId = data.submission_id;
        let {
            submissionLog: { rowData },
            pricing: { skipRevalidation },
        } = getState();
        // TODO: Re-work our handling of account-revalidation so user input is needed to clear validations
        if (skipRevalidation || !(submissionId in rowData)) {
            console.warn('skipping account revalidation push SID:', submissionId);
            return;
        }
        dispatch(clearRowValidations(PAGED_TABLES.SUBMISSION_LOG, submissionId));
    };
}

export function handleDocumentPreview(data: Record<string, any>) {
    return (dispatch: AppDispatch, getState: () => { submissionDocumentsMap: { map: any } }) => {
        const submissionId = data.submission_id;
        let {
            submissionDocumentsMap: { map },
        } = getState();

        if (!(submissionId in map)) {
            console.warn('skipping document preview push SID:', submissionId);
            return;
        }
        dispatch(updateDocumentData([data]));
    };
}

export function handleRowDirty(data: Record<string, any>) {
    return (dispatch: AppDispatch, _getState: (...params: any[]) => any) => {
        const rowId = data.row_id;
        const gridName = data.grid_name;
        let dispatchToGrid = PAGED_TABLES.SUBMISSION_LOG;
        if (gridName in PAGED_TABLES) {
            dispatchToGrid = PAGED_TABLES[gridName];
        } else {
            console.error(`received row-dirty with grid_name=${gridName} which has no entry in PAGED_TABLES. Defaulted to SUBMISSION_LOG`);
        }
        dispatch(setRowToDirty(dispatchToGrid, rowId));
    };
}

export function handleGridRefresh(data: Record<string, any>) {
    return (dispatch: AppDispatch) => {
        const { grid_name, api_params } = data;

        let tableConfig = AppConstants.TABLE_CONFIGS[grid_name];
        if (api_params) {
            const apiParams = api_params;
            const apiParamsKey = getApiParamsKey(apiParams);
            tableConfig = { ...AppConstants.TABLE_CONFIGS[grid_name], apiParams, apiParamsKey };
        }

        dispatch(refreshData(tableConfig));
    };
}

export function handleRowCreatePush(data: Record<string, any>) {
    return (dispatch: AppDispatch, getState: () => RootState) => {
        const { grid_name, api_params } = data;
        let tableConfig = AppConstants.TABLE_CONFIGS[grid_name];
        let {
            accountsState: { accounts },
            global: {
                ENV: { SITE },
            },
        } = getState();

        // RA-3159
        // TODO: Let's add better filtering through channels
        // TODO: Even better, let's not insert rows through pusher, let's push a "Refresh Table" notification and let user do it
        const dataSiteId = data?.data?.site_id;
        if (dataSiteId && dataSiteId !== SITE.id) {
            return;
        }

        if (api_params) {
            const apiParams = api_params;
            const apiParamsKey = getApiParamsKey(apiParams);
            tableConfig = {
                ...AppConstants.TABLE_CONFIGS[grid_name],
                apiParams,
                apiParamsKey,
            };
        }
        dispatch(ensureRowExists(tableConfig, data.row_id, data.data));

        if (grid_name === 'TRANSACTIONS') {
            let accountId = data.data.account_id;
            if (!(accountId in accounts)) {
                console.warn('skipping account refresh push AID:', accountId);
                return;
            }
            dispatch(getAccountDataFromServer(accountId));
        }
    };
}

export function handleDocumentDeletePush(data: Record<string, any>) {
    return (dispatch: AppDispatch) => {
        dispatch(deleteDocumentFromSubmission(data.submission_id, data.document_id));
    };
}

export function handleDocumentCreatePush(data: Record<string, any>) {
    return (dispatch: AppDispatch) => {
        dispatch(handleRowCreatePush(data.row_data));
        dispatch(addDocumentToSubmission(data.submission_id, data.row_data.row_id));
    };
}
