import cx from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import { useWatch, useForm, FormProvider } from 'react-hook-form';
import _ from 'lodash';
import { addYears, format } from 'date-fns';
import { Prompt } from 'react-router';
import { FormButtonGroup } from '@archinsurance-viki/property-jslib/src/components/inputs/v2/form/FormButtonGroup';
import { ThinBottomPanelWrapper } from '@archinsurance-viki/property-jslib/src/containers/panels/ThinBottomPanelWrapper';
import { PolicyLevelOverview } from './PolicyLevelOverview';
import { PolicyTermsHeader } from './PolicyLevelHeader';
import { WindDeductibles } from './WindDeductibles';
import { FloodCoverages } from './FloodCoverages';
import { EarthquakeCoverages } from './EarthquakeCoverages';
import { AopCoverages } from './AopCoverages';
import { BusinessInterruption } from './BusinessInterruption';
import { ClaimsAndCoinsurance } from './ClaimsAndCoinsurance';
import { Margin } from './Margin';
import { EquipmentBreakdown } from './EquipmentBreakdown';
import { OrdinanceAndLaw } from './OrdinanceAndLaw';
import { SinkholeCoverages } from './SinkholeCoverages';
import { PropertyEnhancementEndorsement } from './PropertyEnhancementEndorsement';
import { PolicyTermsSidebar } from './PolicyLevelSidebar';
import { ProRataMEP } from './ProRataMEP';
import { SharedAndLayered } from './SharedAndLayered';
import { useAppSelector } from '../../../hooks/redux';
import { PolicyTermsFormField, peeDefaults, peeOverrides, getFormDefaultsFromGlossary, PolicyTermsFieldName, PolicyTermsFormValues } from '../utils/form';
import { useValidateQuery } from '../../../services/endpoints/policy-coverage';
import { useAppContext } from '../../../hooks/context';
import { useChangeEffect, usePrevUpdate } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { useBeforeUnload } from '@archinsurance-viki/property-jslib/src/hooks/util';
import { getLocationId } from '../../../utils/navurl-helpers';
import { Location } from 'history';
import { useGetPeeDataQuery } from '../../../services/apiSlice';
import { ValidationsTable } from '../../../features/ValidationsPanel';
import { RAE_SITE_ID } from '../../../constants/SiteConstants';
import { PEEQueryResult, PeeOverridesType, PolicyTermsGlossaryResult, PolicyTermsResult } from '../../../ts-types/ApiTypes';
import { usePolicyTermsContext } from '../utils/coverage-utils';
import { OccurrenceLiabilityLimits } from './OccurrenceLiabilityLimits';

const PolicyTermsSection = ({ children, groupKey, fieldName }: { children?: React.ReactNode; groupKey: string; fieldName?: PolicyTermsFieldName }) => {
    const contentRef = useRef<HTMLDivElement>();
    const isIncluded = useWatch<PolicyTermsResult>({ name: fieldName });
    const isCollapsed = !children || !isIncluded;

    const { policyTermsGlossary } = usePolicyTermsContext();
    const POLICY_TERMS_GROUPS = useAppSelector(state => state.global.CONSTANTS.POLICY_TERMS_GROUPS);

    // hide sections if group is inactive
    const glossaryKey = `group_${groupKey.toLocaleLowerCase()}`;
    if (policyTermsGlossary?.[glossaryKey]?.active === false) {
        return null;
    }

    const infoRowsElement = contentRef.current?.querySelector('.info-rows');
    const isEmptySection = !contentRef.current?.hasChildNodes() || (infoRowsElement && !infoRowsElement.hasChildNodes());

    return (
        <div id={groupKey} className="info-block row-editable tw-p-0">
            <div className={cx('tw-transition tw-delay-75 header', { 'tw-border-transparent': isCollapsed || isEmptySection })}>
                <div className="header-text">{POLICY_TERMS_GROUPS[groupKey]}</div>
                {fieldName && (
                    <PolicyTermsFormField hideLabel showError={false}>
                        <FormButtonGroup<PolicyTermsFormValues>
                            className="tw-justify-end tw-bg-inherit"
                            name={fieldName}
                            items={[
                                { display: 'Include', value: true },
                                { display: 'Exclude', value: false },
                            ]}
                        />
                    </PolicyTermsFormField>
                )}
            </div>
            <div
                ref={contentRef}
                className={cx('tw-transition-[all] tw-duration-300', { 'tw-overflow-hidden tw-max-h-0': isCollapsed, 'tw-max-h-[2000px]': !isCollapsed })}
            >
                {children}
            </div>
        </div>
    );
};

const formDefaults = (policyTerms: PolicyTermsResult, policyTermsGlossary: PolicyTermsGlossaryResult, pee: PEEQueryResult, isStdLimitIncluded?: boolean) => {
    return {
        ...getFormDefaultsFromGlossary<PolicyTermsFormValues>(policyTerms, policyTermsGlossary),
        ...peeDefaults(
            _.pick(policyTerms, Object.keys(peeOverrides)) as PeeOverridesType,
            pee,
            isStdLimitIncluded ?? policyTerms.is_pee_standard_limits_included
        ),
    };
};

export const PolicyLevelTerms = () => {
    const { currentQuote, currentTransaction } = useAppContext();

    const featureFlags = useAppSelector(state => state.global.featureFlags);
    const ENV = useAppSelector(state => state.global.ENV);

    const { data: peeCoverage } = useGetPeeDataQuery({ id: currentQuote.policy_coverage_id });

    const [isValidationsOpen, setIsValidationsOpen] = useState(false);

    const { policyTermsData, policyTermsGlossary } = usePolicyTermsContext();

    const formMethods = useForm<PolicyTermsFormValues>({
        defaultValues: formDefaults(policyTermsData, policyTermsGlossary, peeCoverage),
        reValidateMode: 'onSubmit',
    });
    const isStdLimitIncluded = useWatch<PolicyTermsFormValues, 'is_pee_standard_limits_included'>({
        name: 'is_pee_standard_limits_included',
        control: formMethods.control,
    });
    const isAllowProrataWindMepOverride = useWatch<PolicyTermsFormValues, 'is_allow_prorata_wind_mep_override'>({
        name: 'is_allow_prorata_wind_mep_override',
        control: formMethods.control,
    });

    const effectiveDate = useWatch<PolicyTermsFormValues, 'effective_date'>({
        name: 'effective_date',
        control: formMethods.control,
    });

    const windOrHailExcluded = useWatch<PolicyTermsFormValues, 'wind_or_hail_excluded'>({
        name: 'wind_or_hail_excluded',
        control: formMethods.control,
    });

    const earthquakeIncluded = useWatch<PolicyTermsFormValues, 'is_earthquake_included'>({
        name: 'is_earthquake_included',
        control: formMethods.control,
    });

    const form = useWatch<PolicyTermsFormValues, 'form'>({
        name: 'form',
        control: formMethods.control,
    });

    useChangeEffect(policyTermsData, (nextPc, prevPc) => {
        if (nextPc?.id !== prevPc?.id) {
            formMethods.reset(formDefaults(policyTermsData, policyTermsGlossary, peeCoverage, isStdLimitIncluded as boolean));
        }
    });

    useEffect(() => {
        if (
            ENV.SITE.id === RAE_SITE_ID &&
            featureFlags?.enable_rae_cat_model_eq_peril &&
            Object.keys(formMethods.formState.dirtyFields).includes('is_earthquake_included') &&
            earthquakeIncluded
        ) {
            formMethods.setValue('is_earthquake_maximum_occurrence_applied', !earthquakeIncluded, { shouldDirty: true });
        }

        if (
            ENV.SITE.id === RAE_SITE_ID &&
            featureFlags?.enable_rae_cat_model_eq_peril &&
            Object.keys(formMethods.formState.dirtyFields).includes('is_earthquake_included') &&
            earthquakeIncluded
        ) {
            formMethods.setValue('is_earthquake_maximum_occurrence_applied', !earthquakeIncluded, { shouldDirty: true });
        }
    }, [ENV.SITE.id, featureFlags, earthquakeIncluded, formMethods]);

    useEffect(() => {
        if (
            ENV.SITE.id === RAE_SITE_ID &&
            featureFlags?.show_is_sprinkler_leakage_included &&
            (Object.keys(formMethods.formState.dirtyFields).includes('is_earthquake_included') || !earthquakeIncluded)
        ) {
            formMethods.setValue('is_sprinkler_leakage_included', earthquakeIncluded, { shouldDirty: true });
        }
    }, [ENV.SITE.id, featureFlags, earthquakeIncluded, formMethods]);

    useEffect(
        // for New Business only
        //when users selects “Special” in the “Cause of Loss” field in the Policy Terms Page-Overview section/populated via DES import, AOP is set to Include
        () => {
            if (
                Object.keys(formMethods.formState.dirtyFields).includes('form') &&
                ENV.SITE.id !== RAE_SITE_ID &&
                (!currentTransaction || currentTransaction.transaction_type === 'NB') &&
                form === 'SPECIAL'
            ) {
                formMethods.setValue('aop', true, { shouldDirty: true });
            }
        },
        [currentTransaction, currentTransaction?.transaction_type, form, formMethods, ENV.SITE.id]
    );

    useEffect(
        // for New Business only
        //when users selects “Wind and Hail only” in the “Cause of Loss” field in the Policy Terms Page-Overview section/populated via DES import, AOP is set to Exclude,
        () => {
            if (
                Object.keys(formMethods.formState.dirtyFields).includes('form') &&
                ENV.SITE.id !== RAE_SITE_ID &&
                (!currentTransaction || currentTransaction.transaction_type === 'NB') &&
                form === 'WIND_ONLY'
            ) {
                formMethods.setValue('aop', false, { shouldDirty: true });
            }
        },
        [currentTransaction, currentTransaction?.transaction_type, form, formMethods, ENV.SITE.id]
    );

    useEffect(
        // for renewals
        // if Cause of Loss = Special, then DEFAULT AOP to Included
        () => {
            if (
                Object.keys(formMethods.formState.dirtyFields).includes('form') &&
                ENV.SITE.id !== RAE_SITE_ID &&
                currentTransaction?.transaction_type === 'RB' &&
                form === 'SPECIAL'
            ) {
                formMethods.setValue('aop', true, { shouldDirty: true });
            }
        },
        [currentTransaction, currentTransaction?.transaction_type, form, formMethods, ENV.SITE.id]
    );

    useEffect(
        // for renewals
        // If  Cause of Loss = Wind and Hail Only then DEFAULT AOP to Excluded
        () => {
            if (
                Object.keys(formMethods.formState.dirtyFields).includes('form') &&
                ENV.SITE.id !== RAE_SITE_ID &&
                currentTransaction?.transaction_type === 'RB' &&
                form === 'WIND_ONLY'
            ) {
                formMethods.setValue('aop', false, { shouldDirty: true });
            }
        },
        [currentTransaction, currentTransaction?.transaction_type, form, formMethods, ENV.SITE.id]
    );

    useEffect(() => {
        if (Object.keys(formMethods.formState.dirtyFields).includes('effective_date')) {
            formMethods.setValue('expiration_date', format(addYears(new Date(effectiveDate as string), 1), 'MM/dd/yyyy'), { shouldDirty: true });
        }
    }, [formMethods, effectiveDate]);

    useEffect(() => {
        if (!isAllowProrataWindMepOverride) {
            formMethods.setValue('is_allow_prorata_wind_mep', !windOrHailExcluded, { shouldDirty: true });
        }
    }, [formMethods, windOrHailExcluded, isAllowProrataWindMepOverride]);

    useEffect(() => {
        const locationId = getLocationId();
        if (locationId) {
            const el = window.document.getElementById(locationId);
            if (el) {
                el.scrollIntoView();
            }
        }
    }, []);

    const { data: validationData } = useValidateQuery({ id: currentQuote.policy_coverage_id });

    const allValidationErrors = [
        ...Object.values(validationData?.non_field_errors ?? {}).map(message => ({ message })),
        ...Object.entries(validationData?.field_errors ?? {}).map(([field, errors]) => ({
            message: `${policyTermsGlossary?.[field].label ?? 'Error'}: ${errors.join('\n')}`,
            buttonTitle: 'Jump to Error',
            onClick: () => formMethods.setFocus(field as PolicyTermsFieldName),
        })),
    ];
    const hasValidationErrors = allValidationErrors.length > 0;
    usePrevUpdate(hasValidationErrors, prevHasValidationErrors => {
        if (hasValidationErrors && !prevHasValidationErrors) {
            setIsValidationsOpen(true);
        } else if (!hasValidationErrors && prevHasValidationErrors) {
            setIsValidationsOpen(false);
        }
    });

    useBeforeUnload(!_.isEmpty(formMethods.formState.dirtyFields));

    return (
        <FormProvider {...formMethods}>
            <div className="tw-flex tw-flex-col tw-flex-1 tw-overflow-hidden">
                <PolicyTermsHeader />
                <Prompt
                    message={(location: Location, _action: string) => {
                        return !location.pathname.includes('coverage_options') && !_.isEmpty(formMethods.formState.dirtyFields)
                            ? `You are leaving this page with active changes, do you want to discard them?`
                            : true;
                    }}
                />
                <div className="ventus-content fd-r content-hidden tw-flex-1 tw-h-full">
                    <div className="w-55 flex column overflow-y tw-pr-2 tw-h-full [&_.info-rows:empty]:tw-m-0 [&_.info-rows:empty]:tw-border-0">
                        <PolicyTermsSection groupKey="OVERVIEW">
                            <PolicyLevelOverview />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="SHARED_AND_LAYERED">
                            <SharedAndLayered />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="OCCURRENCE_LIMIT_OF_LIABILITY" fieldName="occurrence_liability_limit_included">
                            <OccurrenceLiabilityLimits />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="WIND" fieldName="wind_or_hail_excluded">
                            <WindDeductibles />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="PRO_RATA_WIND_MEP">
                            <ProRataMEP />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="FLOOD" fieldName="is_flood_included">
                            <FloodCoverages />
                        </PolicyTermsSection>

                        <PolicyTermsSection
                            groupKey="EARTHQUAKE"
                            {...(featureFlags?.enable_policy_eq_include_exclude_field ? { fieldName: 'is_earthquake_included' } : {})}
                        >
                            <EarthquakeCoverages />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="AOP" fieldName="aop">
                            <AopCoverages />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="BUSINESS_INTERRUPTION">
                            <BusinessInterruption />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="CLAIMS_VALUATION_AND_COINSURANCE">
                            <ClaimsAndCoinsurance />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="MARGIN">
                            <Margin />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="TERROR" fieldName="terror" />

                        <PolicyTermsSection groupKey="EQUIPMENT_BREAKDOWN" fieldName="equipment_breakdown">
                            <EquipmentBreakdown />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="ORDINANCE_OR_LAW">
                            <OrdinanceAndLaw />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="SINKHOLE" fieldName="sinkhole">
                            <SinkholeCoverages />
                        </PolicyTermsSection>

                        <PolicyTermsSection groupKey="PROPERTY_ENHANCEMENT_ENDORSEMENT" fieldName="prop_enhancement_endorsement">
                            <PropertyEnhancementEndorsement />
                        </PolicyTermsSection>
                    </div>
                    <PolicyTermsSidebar />
                </div>
                <ThinBottomPanelWrapper
                    tabs={[`${allValidationErrors.length} Error${allValidationErrors.length !== 1 ? 's' : ''}`]}
                    activeTabIndex={isValidationsOpen ? 0 : null}
                    onTabSelect={() => setIsValidationsOpen(prev => !prev)}
                    render={() => (
                        <div className="tw-flex tw-flex-col tw-gap-2 tw-h-[300px] tw-pb-8">
                            <h2>Validation Errors</h2>
                            <ValidationsTable validations={allValidationErrors} />
                        </div>
                    )}
                />
            </div>
        </FormProvider>
    );
};
