import useQuoteId, { useQuoteFinalPremium, useQuotePolicyCoverage } from '../../hooks/quotes';
import Grid, { Label, Value } from '../../components/pricing-page/PricingGrid';
import { useGetMarginalPmlsQuery } from '../../services/apiSlice';
import { SubmissionDataType } from '../../ts-types/DataTypes';
import { RenderMarginalPml } from '../../components/pricing-page';

const RMSAIRMetricsPanel = ({
    showPricingTabTodo,
    showLayeringAALsPMLs,
    showTechnicalPremiumByPeril,
}: {
    currentSubmission: SubmissionDataType;
    showPricingTabTodo: boolean;
    showLayeringAALsPMLs: boolean;
    showTechnicalPremiumByPeril: boolean;
}) => {
    const { finalPremium } = useQuoteFinalPremium();
    const { policyCoverage } = useQuotePolicyCoverage();
    const quoteId = useQuoteId();
    const { data: marginPmls, isLoading } = useGetMarginalPmlsQuery({ quoteId: +quoteId }, { skip: !quoteId });

    if (!finalPremium) {
        return null;
    }
    const {
        air_aep_200,
        air_aep_250,
        air_aep_1000,
        air_layerless_oep_200,
        air_layerless_oep_250,
        air_layerless_oep_1000,
        air_oep_200,
        air_oep_250,
        air_oep_1000,
        air_cat_loss_cost_final,
        air_layerless_cat_loss_cost_final,
        air_pct_of_technical,
        air_technical_aop_premium,
        air_technical_premium_before_tf,
        air_total_cat_premium_final,
        air_total_earthquake_cat_premium_final,
        air_total_wind_cat_premium_final,
        charged_premium_before_tf,
        inland_flood_premium_final,
        rms_aep_200,
        rms_aep_250,
        rms_aep_1000,
        rms_oep_200,
        rms_oep_250,
        rms_oep_1000,
        rms_cat_loss_cost_final,
        rms_pct_of_technical,
        rms_technical_aop_premium,
        rms_technical_premium_before_tf,
        rms_total_cat_premium_final,
        rms_total_earthquake_cat_premium_final,
        rms_total_wind_cat_premium_final,
        target_minimum_charged_premium,
    } = finalPremium;

    const { aop, is_earthquake_included, is_flood_included, wind_or_hail_excluded } = policyCoverage;
    const target_percent_of_charged_premium = (+charged_premium_before_tf / parseFloat(target_minimum_charged_premium)) * 100;
    return (
        <>
            {/*<div className="header">Pricing Metrics ( DH = Dont have )</div>*/}

            <Grid columns={5} className="padding-light metrics-grid">
                <label className="grid-5-wide secondary-styling">Charged Premium as % of</label>

                <Label>Target</Label>
                <Value
                    className={target_percent_of_charged_premium < 100 ? 'red font-semibold' : ''}
                    format={{ type: 'number', placeholder: 'N/A', postfix: '%' }}
                    value={!isNaN(target_percent_of_charged_premium) ? Math.floor(target_percent_of_charged_premium) : null}
                />
                <div className="grid-3-wide"></div>

                <Label>AIR Technical</Label>
                <Value format={{ type: 'number', decimals: 0, placeholder: 'N/A', postfix: '%' }} value={air_pct_of_technical} />
                <div className="grid-3-wide"></div>

                <Label>RMS Technical</Label>
                <Value format={{ type: 'number', decimals: 0, placeholder: 'N/A', postfix: '%' }} value={rms_pct_of_technical} />
                <div className="grid-3-wide"></div>
            </Grid>

            <Grid columns={5} className="padding-light gap-0125">
                {showLayeringAALsPMLs && (
                    <>
                        <label className="secondary-styling">AAL</label>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05 text-center">AIR</div>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05 text-center">RMS</div>
                        <label className="sub-label">Before Layer & Participation</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_layerless_cat_loss_cost_final} />
                        <div />
                        <div />
                        <div />
                        <label className="sub-label">After Layer & Participation</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_cat_loss_cost_final} />
                        <div />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={rms_cat_loss_cost_final} />
                        <div />
                        <div className="grid-5-wide grid-spacer"></div>
                        <label className="secondary-styling">Occ PML - Before Layer and Participation</label>
                        <div className="grid-4-wide"></div>
                        <label className="sub-label">1:200</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_layerless_oep_200} />
                        <div className="grid-3-wide"></div>
                        <label className="sub-label">1:250</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_layerless_oep_250} />
                        <div className="grid-3-wide"></div>
                        <label>1:1000</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_layerless_oep_1000} />
                        <div className="grid-3-wide"></div>
                        <div className="grid-5-wide grid-spacer"></div>
                        <label className="secondary-styling">Occ PML - After Layer and Participation</label>
                        <div className="grid-4-wide"></div>
                        <label className="sub-label">1:200</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_oep_200} />
                        <Value
                            format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }}
                            value={parseInt(air_oep_200) / +charged_premium_before_tf}
                        />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={rms_oep_200} />
                        <Value
                            format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }}
                            value={parseInt(rms_oep_200) / +charged_premium_before_tf}
                        />
                        <label className="sub-label">1:250</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_oep_250} />
                        <Value
                            format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }}
                            value={parseInt(air_oep_250) / +charged_premium_before_tf}
                        />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={rms_oep_250} />
                        <Value
                            format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }}
                            value={parseInt(rms_oep_250) / +charged_premium_before_tf}
                        />
                        <label>1:1000</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_oep_1000} />
                        <Value
                            format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }}
                            value={parseInt(air_oep_1000) / +charged_premium_before_tf}
                        />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={rms_oep_1000} />
                        <Value
                            format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }}
                            value={parseInt(rms_oep_1000) / +charged_premium_before_tf}
                        />
                        <div className="grid-5-wide grid-spacer"></div>
                    </>
                )}
                {showLayeringAALsPMLs ? (
                    <>
                        <label className="secondary-styling">Agg PML - After Layer and Participation</label>
                        <div className="grid-4-wide"></div>
                    </>
                ) : (
                    <>
                        <label className="secondary-styling">Aggregate PML - Stand Alone</label>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05">AIR</div>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05">RMS</div>
                    </>
                )}
                <label className="sub-label">1:200</label>
                <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_aep_200} />
                <Value format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }} value={parseInt(air_aep_200) / +charged_premium_before_tf} />
                <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={rms_aep_200} />
                <Value format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }} value={parseInt(rms_aep_200) / +charged_premium_before_tf} />
                <label className="sub-label">1:250</label>
                <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_aep_250} />
                <Value format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }} value={parseInt(air_aep_250) / +charged_premium_before_tf} />
                <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={rms_aep_250} />
                <Value format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }} value={parseInt(rms_aep_250) / +charged_premium_before_tf} />
                <label>1:1000</label>
                <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={air_aep_1000} />
                <Value format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }} value={parseInt(air_aep_1000) / +charged_premium_before_tf} />
                <Value format={{ type: 'number', decimals: 0, delimiter: ',', placeholder: 'N/A' }} value={rms_aep_1000} />
                <Value format={{ type: 'number', decimals: 1, postfix: 'x', placeholder: 'N/A' }} value={parseInt(rms_aep_1000) / +charged_premium_before_tf} />
                <div className="grid-5-wide grid-spacer"></div>
                {showLayeringAALsPMLs ? (
                    <>
                        <label className="secondary-styling">Agg PML - Marginal</label>
                        <div className="grid-4-wide"></div>
                    </>
                ) : (
                    <>
                        <label className="secondary-styling">Aggregate PML - Marginal</label>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05">AIR</div>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05">RMS</div>
                    </>
                )}
                <label>1:200</label>
                <RenderMarginalPml isLoading={isLoading} numerator={marginPmls?.air_marginal_aep_200} denominator={charged_premium_before_tf} />
                <div></div>
                <div></div>
                <label>1:250</label>
                <RenderMarginalPml isLoading={isLoading} numerator={marginPmls?.air_marginal_aep_250} denominator={charged_premium_before_tf} />

                <div></div>
                <div></div>
                <label>1:1000</label>
                <RenderMarginalPml isLoading={isLoading} numerator={marginPmls?.air_marginal_aep_1000} denominator={charged_premium_before_tf} />

                <div></div>
                <div></div>
                <div className="grid-5-wide grid-spacer"></div>
                <label className="secondary-styling">Technical Premium by Peril</label>
                {showLayeringAALsPMLs ? (
                    <div className="grid-4-wide"></div>
                ) : (
                    <>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05">AIR</div>
                        <div className="sub-header grid-2-wide border-bottom margin-left-05">RMS</div>
                    </>
                )}
                {showTechnicalPremiumByPeril ? (
                    <>
                        <label>AOP</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={aop ? air_technical_aop_premium : null} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={aop ? (+air_technical_aop_premium / +air_technical_premium_before_tf) * 100 : null}
                        />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={aop ? rms_technical_aop_premium : null} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={aop ? (+rms_technical_aop_premium / +rms_technical_premium_before_tf) * 100 : null}
                        />
                        <label>Wind</label>
                        <Value
                            format={{ type: 'number', decimals: 0, delimiter: ',' }}
                            value={wind_or_hail_excluded ? null : air_total_wind_cat_premium_final}
                        />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={wind_or_hail_excluded ? null : (parseInt(air_total_wind_cat_premium_final) / +air_technical_premium_before_tf) * 100}
                        />
                        <Value
                            format={{ type: 'number', decimals: 0, delimiter: ',' }}
                            value={wind_or_hail_excluded ? null : rms_total_wind_cat_premium_final}
                        />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={wind_or_hail_excluded ? null : (parseInt(rms_total_wind_cat_premium_final) / +rms_technical_premium_before_tf) * 100}
                        />
                        <label>Earthquake</label>
                        <Value
                            format={{ type: 'number', decimals: 0, delimiter: ',' }}
                            value={is_earthquake_included ? air_total_earthquake_cat_premium_final : null}
                        />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={is_earthquake_included ? (parseInt(air_total_earthquake_cat_premium_final) / +air_technical_premium_before_tf) * 100 : null}
                        />
                        <Value
                            format={{ type: 'number', decimals: 0, delimiter: ',' }}
                            value={is_earthquake_included ? rms_total_earthquake_cat_premium_final : null}
                        />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={is_earthquake_included ? (parseInt(rms_total_earthquake_cat_premium_final) / +rms_technical_premium_before_tf) * 100 : null}
                        />
                        <label>Flood</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={is_flood_included ? inland_flood_premium_final : null} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={is_flood_included ? (parseInt(inland_flood_premium_final) / +air_technical_premium_before_tf) * 100 : null}
                        />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={is_flood_included ? inland_flood_premium_final : null} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={is_flood_included ? (parseInt(inland_flood_premium_final) / +rms_technical_premium_before_tf) * 100 : null}
                        />
                    </>
                ) : (
                    <>
                        <label>AOP</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={air_technical_aop_premium} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={(+air_technical_aop_premium / +air_technical_premium_before_tf) * 100}
                        />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={rms_technical_aop_premium} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={(+rms_technical_aop_premium / +rms_technical_premium_before_tf) * 100}
                        />
                        <label>Catastrophe</label>
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={air_total_cat_premium_final} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={(parseInt(air_total_cat_premium_final) / +air_technical_premium_before_tf) * 100}
                        />
                        <Value format={{ type: 'number', decimals: 0, delimiter: ',' }} value={rms_total_cat_premium_final} />
                        <Value
                            format={{ type: 'number', decimals: 0, postfix: '%' }}
                            value={(parseInt(rms_total_cat_premium_final) / +rms_technical_premium_before_tf) * 100}
                        />
                    </>
                )}
                {showPricingTabTodo && (
                    <>
                        <div className="grid-5-wide grid-spacer"></div>

                        <label className="secondary-styling">Expected Loss Ratio</label>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>

                        <label>AOP</label>
                        <div></div>
                        <div>TODO%</div>
                        <div></div>
                        <div>TODO%</div>

                        <label>Catastrophe</label>
                        <div></div>
                        <div>TODO%</div>
                        <div></div>
                        <div>TODO%</div>

                        <label>Total</label>
                        <div></div>
                        <div>TODO%</div>
                        <div></div>
                        <div>TODO%</div>
                    </>
                )}
            </Grid>
        </>
    );
};

export default RMSAIRMetricsPanel;
